/*! _variables.scss | Huro | Css ninja 2020-2021 */

/*
    1. Main Colors
    2. Global colors
*/

$fullhd-enabled: false;
$body-size: 14px !default;

/* ==========================================================================
1. Main Colors
========================================================================== */

$primary: $purple;
$secondary: $orange;
$accent: #797bf2;

/* ==========================================================================
2. Global colors
========================================================================== */

$body-color: #f9f9f9;

$white: #fff;
$smoke-white: #fcfcfc;
$light-white: #f9f9f9;

$placeholder: #cecece;
$muted-grey: #999;
$fade-grey: #ededed;
$accent-grey: #ccc;
$background-grey: #f0f0f0;
$section-grey: #fcfcfc;
$medium-grey: #ddd;
$light-grey: #eee;
$lighter-grey: #e5e5e5;
$widget-grey: #f5f6fa;

$dark-text: #283252;
$light-text: #a2a5b9;
$title-grey: #A9ABAC;

$danger: $red;
$info: $blue;
$success: $green;
$warning: $yellow;

$h-purple: #8269B2;
$h-blue: #37C3FF;
$h-red: #F92B60;
$h-orange: #FFA981;
$h-yellow: #FFD66E;
$h-green: #93E088;
$h-pink: #FF9EB8;
$h-light-blue: #77D2F3;

$active: #6DCFF6;

$sidebar: #344258;

$dark-sidebar: #232326;
$dark-dark-text: #aaaab3;



