body.community{
  .component-home-header{
    margin-top: -25px;
    .block-header {
      border-radius: 0;
      padding: 0;
      background: none;
      box-shadow: none;
      
      > .bg-image-section{
        border-radius: 0;
        z-index: 0;
        height: auto;
        min-height: 200px;
        max-height: 300px;
        @media(min-width: $tablet) {
          aspect-ratio: 4 / 1;
        }
        
        // background-attachment: fixed;
        width: 100%;
        /* Preserve aspect ratio */
        min-width: 100%;
        // min-height: 100%;
      }
      .block-heading {
        position: relative;
        .block-description{
          .title-user{
            margin-top: 70px;
          }
          .level{
            margin-top: 10px;
          }
        }
        .block-icon{
          position: absolute;
          left: 0;
          right: 0;
          z-index: 5;
          top: -128px;
          width: 128px;
          margin-left: auto;
          margin-right: auto;
          span{
            margin-top: 0;
          }
          a{
            position: relative;
          }
          .is-live{
            position: absolute;
            left: 50%;
            transform: translate(-50%, -75%);
            background-color: $color-youtube;
            color: $white;
            font-weight: bold;
            border:none;
            left:0;
            right: 0;
            bottom:-32px;
            margin-left: auto;
            margin-right: auto;
            z-index: 6;
          }
        }
        .block-avatar{
          position: absolute;
          left: 20px;
          // right: 0;
          z-index: 5;
          top: -60px;
          width: 128px;
          margin-left: auto;
          margin-right: auto;
          span{
            margin-top: 0;
          }
          a{
            position: relative;
          }
          .is-live{
            position: absolute;
            left: 50%;
            transform: translate(-50%, -75%);
            background-color: $color-youtube;
            color: $white;
            font-weight: bold;
            border:none;
            left:0;
            right: 0;
            bottom:-32px;
            margin-left: auto;
            margin-right: auto;
            z-index: 6;
          }
        }
      }
      
      
    }
    .bg-image-section{
      position: relative;
      form{
        padding-top: 25px;
      }
      .block-links{
        position: absolute;
        left: 10px;
        bottom: 10px;
        background: #ffffff7d;
        border-radius: 4px;
        @media(max-width: $tablet) {
          display: none;
        }
        &.block-links-right{
          left: auto;
          right: 10px;
        }
      }
    }
  }
}