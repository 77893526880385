/*! _lists.scss | Huro | Css ninja 2020-2021 */

/*
    1. User List
    2. User List Dark mode
*/

/* ==========================================================================
1. User List
========================================================================== */

.user-list {

    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 6px 0;
    }

    .user-list-avatar img {
        width: 40px;
        height: 40px;
        border-radius: 100px;
    }

    .user-list-info {
        margin: 0 10px;

        .name {
            font-family: $font-alt;
            font-size: .95rem;
            font-weight: 600;
            color: $dark-text;
        }

        .position {
            font-family: $font;
            font-size: 12px;
            color: $light-text;
        }
    }

    .user-list-status {
        height: 10px;
        width: 10px;
        border-radius: 100px;
        background: $fade-grey;
        margin-left: auto;
        margin-right: 1rem;

        &.is-offline {
            background: $fade-grey;
        }

        &.is-online {
            background: $success;
        }

        &.is-busy {
            background: $warning;
        }
    }

    .user-list-icons {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
            width: 32px;
            border-radius: 50%;
            color: lighten($light-text, 15%);
            font-size: .9rem;
            transition: all .3s;

            &:hover {
                color: $light-text;
                background: lighten($fade-grey, 3%);
            }
        }
    }
}

/* ==========================================================================
2. User List Dark mode
========================================================================== */

body {
    &.is-dark {
        .user-list {
            .user-list-icons{
                a:hover {
                    background: lighten($dark-sidebar, 10%);
                }
            }
        }
    }
}



.list-view {
    &.list-view-v1 {
        .list-view-item {
            @extend .r-card;

            margin-bottom: 16px;
            padding: 16px;

            .list-view-item-inner {
                display: flex;
                align-items: center;

                .meta-left {
                    margin-left: 16px;

                    h3 {
                        font-family: $font-alt;
                        color: $dark-text;
                        font-weight: 600;
                        font-size: 1rem;
                        line-height: 1;
                    }

                    > span:not(.tag) {
                        font-size: .9rem;
                        color: $light-text;

                        svg {
                            height: 12px;
                            width: 12px;
                        }
                    }
                }

                .meta-right {
                    margin-left: auto;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .tags {
                        margin-right: 30px;
                        margin-bottom: 0;

                        .tag {
                            margin-bottom: 0;
                        }
                    }

                    .stats {
                        display: flex;
                        align-items: center;
                        margin-right: 30px;

                        .stat {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            color: $light-text;

                            > span {
                                font-family: $font;

                                &:first-child {
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                    color: $dark-text;
                                    line-height: 1.4;
                                }

                                &:nth-child(2) {
                                    text-transform: uppercase;
                                    font-family: $font-alt;
                                    font-size: .75rem;
                                }
                            }

                            svg {
                                height: 16px;
                                width: 16px;
                            }

                            i {
                                font-size: 1.4rem;
                            }
                        }

                        .separator {
                            height: 25px;
                            width: 2px;
                            border-right: 1px solid darken($fade-grey, 3%);
                            margin: 0 16px;
                        }
                    }

                    .network {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        min-width: 145px;

                        > span {
                            font-family: $font;
                            font-size: .9rem;
                            color: $light-text;
                            margin-left: 6px;
                        }
                    }

                    .dropdown {
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
3. List View V1 Dark mode
========================================================================== */

body {
    &.is-dark {
        .list-view {
            &.list-view-v1 {
                .list-view-item {
                    .list-view-item-inner {
                        .meta-left {
                            h3 {
                                color: $dark-dark-text !important;
                            }
                        }

                        .meta-right {
                            .stats {
                                .stat {
                                    span {
                                        &:first-child {
                                            color: $dark-dark-text;
                                        }
                                    }
                                }

                                .separator {
                                    border-color: lighten($dark-sidebar, 16%) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}