/*! _cards.scss | Huro | Css ninja 2020-2021 */

/*
    1. Simple Cards
    2. Simple Cards Dark mode
*/

/* ==========================================================================
1. Simple Cards
========================================================================== */

.r-card {
    flex: 1;
    display: inline-block;
    width: 100%;
    padding: 20px;
    background-color: $white;
    border-radius: 10px;
    border: 1px solid darken($fade-grey, 3%);
    transition: all .3s;
}

.s-card {
    flex: 1;
    display: inline-block;
    width: 100%;
    padding: 20px;
    background-color: $white;
    border-radius: 6px;
    border: 1px solid darken($fade-grey, 3%);
    transition: all .3s;
}

.l-card {
    flex: 1;
    display: inline-block;
    width: 100%;
    padding: 20px;
    background-color: $white;
    border-radius: 16px;
    border: 1px solid darken($fade-grey, 3%);
    transition: all .3s;
}

.r-card, .s-card, .l-card {
    &.has-margin-bottom {
        margin-bottom: 1.5rem;
    }

    &.is-flat {
        box-shadow: none;
    }

    &.is-raised {
        box-shadow: $light-box-shadow;
    }

    &.is-primary {
        background: $primary;
        border-color: $primary;

        .title, .subtitle, h1, h2, h3, h4, p {
            color: $smoke-white !important;
        }
    }

    &.is-secondary {
        background: $secondary !important;
        border-color: $secondary !important;

        .title, .subtitle, h1, h2, h3, h4, p {
            color: $smoke-white !important;
        }
    }

    &.is-info {
        background: $info !important;
        border-color: $info !important;

        .title, .subtitle, h1, h2, h3, h4, p {
            color: $smoke-white !important;
        }
    }

    &.is-success {
        background: $success !important;
        border-color: $success !important;

        .title, .subtitle, h1, h2, h3, h4, p {
            color: $smoke-white !important;
        }
    }

    &.is-warning {
        background: $warning !important;
        border-color: $warning !important;

        .title, .subtitle, h1, h2, h3, h4, p {
            color: $smoke-white !important;
        }
    }

    &.is-danger {
        background: $danger !important;
        border-color: $danger !important;

        .title, .subtitle, h1, h2, h3, h4, p {
            color: $smoke-white !important;
        }
    }

    .card-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left, .right {
            display: flex;
            align-items: center;
        }

        .left {
            .title {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }

        .right {
            justify-content: flex-end;
        }
    }

    .card-inner {
        padding-top: 1.75rem;
    }
}

/* ==========================================================================
2. Simple Cards Dark mode
========================================================================== */

body {
    &.is-dark {
        .r-card, .s-card, .l-card {
            background: lighten($dark-sidebar, 6%);
            border-color: lighten($dark-sidebar, 12%);

            &.is-primary {
                background: $accent;
                border-color: $accent;
            }

            .h-avatar {
                img {
                    border-color: lighten($dark-sidebar, 6%) !important;
                }
            }

            .avatar-stack {
                .h-avatar {
                    border-color: lighten($dark-sidebar, 6%) !important;

                    .avatar {
                        &.is-more {
                            .inner {
                                border-color: lighten($dark-sidebar, 6%) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
3. Advanced Cards
========================================================================== */

.l-card-advanced {
    border-radius: 16px;
}

.r-card-advanced {
    border-radius: 10px;
}

.s-card-advanced {
    border-radius: 6px;
}

.r-card-advanced, .s-card-advanced, .l-card-advanced {
    flex: 1;
    display: inline-block;
    width: 100%;
    background-color: $white;
    border: 1px solid darken($fade-grey, 3%);
    transition: all .3s;

    .card-head, .card-foot {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
        }

        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .card-head {
        border-bottom: 1px solid darken($fade-grey, 3%);
    }

    .card-foot {
        border-top: 1px solid darken($fade-grey, 3%);
    }

    .card-body {
        padding: 20px;
    }
}

/* ==========================================================================
4. Advanced Cards Dark mode
========================================================================== */

body {
    &.is-dark {
        .r-card-advanced, .s-card-advanced, .l-card-advanced {
            background: lighten($dark-sidebar, 6%);
            border-color: lighten($dark-sidebar, 12%);

            .card-head, .card-foot {
                border-color: lighten($dark-sidebar, 12%);
            }

            .h-avatar {
                img {
                    border-color: lighten($dark-sidebar, 6%) !important;
                }
            }

            .avatar-stack {
                .h-avatar {
                    border-color: lighten($dark-sidebar, 6%) !important;

                    .avatar {
                        &.is-more {
                            .inner {
                                border-color: lighten($dark-sidebar, 6%) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
5. Bulma Cards
========================================================================== */

.card {
    &.h-card {
        box-shadow: none;
        border-radius: 4px;
        border: 1px solid darken($fade-grey, 3%);

        .card-header {
            box-shadow: none;

            .card-header-title {
                font-family: $font-alt;
                font-weight: 600;
            }
        }

        .card-image {
            img {
                object-fit: cover;
            }
        }

        .card-footer {
            a {
                font-family: $font;
                color: $light-text;
            }
        }
    }
}

/* ==========================================================================
6. Bulma Cards Dark mode
========================================================================== */

body {
    &.is-dark {
        .card {
            &.h-card {
                background: lighten($dark-sidebar, 6%);
                border-color: lighten($dark-sidebar, 12%);

                .card-header {
                    .card-header-title {
                        color: $dark-dark-text;
                    }
                }

                .card-footer {
                    border-color: lighten($dark-sidebar, 12%);

                    a {
                        border-color: lighten($dark-sidebar, 12%);
                    }
                }

                .h-avatar {
                    .badge {
                        border-color: lighten($dark-sidebar, 6%);
                    }
                }
            }
        }
    }
}

.long-card {
    @extend .l-card;

    position: relative;
    min-height: 300px;

    &:hover {
        .long-card-overlay {
            opacity: .5;
            pointer-events: all;
        }

        .long-card-text-overlay {
            opacity: 1;
            pointer-events: all;

            .top, .bottom {
                transform: translateY(0);
                opacity: 1;
                font-weight: bold;
                text-align: left;
            }
        }
    }

    .long-card-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $dark-sidebar;
        opacity: 0;
        pointer-events: none;
        z-index: 1;
        cursor: pointer;
        border-radius: 16px;
        transition: all .3s;
    }

    .long-card-text-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        font-family: $font;
        opacity: 0;
        pointer-events: none;
        z-index: 2;
        overflow: hidden;
        cursor: pointer;

        .top, .bottom {
            transition: all .2s;
            opacity: 0;
            transition-delay: .15s;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $smoke-white;
        }

        .top {
            transform: translateY(-30px);
            font-weight: 500;
            font-size: .75rem;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        .bottom {
            font-size: .9rem;
            transform: translateY(30px);
        }
    }
}

.overview-card {
    @extend .l-card;

    font-family: $font;

    &.is-metrics, &.is-heatmap {
        padding: 30px;
    }

    .card-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.has-margin-bottom {
            margin-bottom: 16px;
        }

        h4 {
            color: darken($light-text, 2%);
        }

        h3 {
            font-family: $font-alt;
            font-size: 1rem;
            font-weight: 600;
            color: $dark-text;
        }
    }

    .card-metric {
        font-weight: 700;
        font-size: 2.8rem;
        color: $dark-text;
        padding: 6px 0 16px 0;

        &.is-smaller {
            font-size: 2.5rem;
        }
    }

    .card-foot {
        display: flex;
        align-items: center;

        &.is-up {
            span span {
                color: $h-green;

                svg {
                    transform: rotate(-45deg);
                }
            }
        }

        &.is-down {
            span span {
                color: $h-red;

                svg {
                    transform: rotate(45deg);
                }
            }
        }

        span {
            display: flex;
            color: $light-text;

            span {
                margin-right: 8px;
                font-weight: 500;

                svg {
                    height: 18px;
                    width: 18px;
                    stroke-width: 3px;
                }
            }
        }
    }

    .cloud-container {
        position: relative;
        width: 512px;
        height: 480px;

        .cloud {
            width: 512px;
            height: 480px;
            position: absolute;
        }

        .cloud-element {
            font-size: 2em;
            font-family: $font;
            text-align: center;

            &.size-2 {
                font-size: 3rem;
            }

            &.size-3 {
                font-size: 4rem;
            }

            &.is-bold {
                a {
                    font-weight: 600;
                }
            }

            a {
                color: $dark-text;
            }
        }
    }

    .metric {
        .metric-head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                color: darken($light-text, 2%);
            }
        }

        .metric {
            font-weight: 700;
            font-size: 2.8rem;
            color: $dark-text;
            padding: 10px 0;

            &.is-smaller {
                font-size: 2.5rem;
            }
        }

        .metric-foot {
            display: flex;
            align-items: center;

            &.is-up {
                span span {
                    color: $h-green;

                    svg {
                        transform: rotate(-45deg);
                    }
                }
            }

            &.is-down {
                span span {
                    color: $h-red;

                    svg {
                        transform: rotate(45deg);
                    }
                }
            }

            span {
                display: flex;
                color: $light-text;

                span {
                    margin-right: 8px;
                    font-weight: 500;

                    svg {
                        height: 18px;
                        width: 18px;
                        stroke-width: 3px;
                    }
                }
            }
        }
    }
}