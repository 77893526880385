body.patients{

  .text-center {
    align-self: stretch;
    text-align: center;
  }
  
  .text-large {
    color: #001965;
    font-size: 36px;
    line-height: 32.72px;
    word-wrap: break-word;
  }
  
  .text-small {
    color: #001965;
    font-size: 16px;
    word-wrap: break-word;
  }
  
  .text-blue {
    color: #001965;
  }
  
  .text-pink {
    color: #EBADC1;
  }
  
  .text-gray {
    color: #939AA7;
  }
  
  .font-apis {
    font-family: 'Apis';
  }
  
  .font-weight-400 {
    font-weight: 400;
  }
  
  .font-weight-500 {
    font-weight: 500;
  }
  
  .font-weight-700 {
    font-weight: 700;
  }

  .button {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    gap: 28px;
    display: inline-flex;
    color: $primary;
  }
  
  .button-container {
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    gap: 28px;
    display: inline-flex;
  }
  
  .button-blue {
    background: #001965;
  }
  
  .button-text {
    font-size: 20px;
    line-height: 29px;
    word-wrap: break-word;
    color: $white;
  }

  .button-default{
    background: $white;
    color: $primary;
    font-size: 20px;
    font-family: Apis;
    font-weight: 500;
    line-height: 29px;
    word-wrap: break-word;
    &:disabled, &.disabled {
      background: $grey;
      cursor: not-allowed;
    }
  }

  .button-primary{
    background: $primary;
    color: $white;
    font-size: 20px;
    font-family: Apis;
    font-weight: 500;
    line-height: 29px;
    word-wrap: break-word;
    &:disabled, &.disabled {
      background: $grey;
      cursor: not-allowed;
    }
  }

  .button-secondary{
    background: none;
    color: $grey;
    font-family: Apis;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    word-wrap: break-word
  }

  .modal-agreement-terms{
    .modal-card-body{
      border-radius: 15px;
      .title{
        color: $primary;
        font-size: 32px;
        font-family: Apis;
        font-weight: 500;
        line-height: 38.50px;
        word-wrap: break-word;
      }
      .textarea-terms{
        height: 40vh;
      }
      .terms-checkbox-container{
        margin: auto;
        padding-left: 16px; 
        padding-right: 16px; 
        padding-top: 6px; 
        padding-bottom: 4px; 
        background: $white; 
        border-radius: 15px; 
        border: 0.50px $primary solid; 
        justify-content: center; 
        align-items: center; 
        gap: 12px; 
        display: inline-flex;
        .input-checkbox{
          opacity: 1;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 16px;
          height: 16px;
          border: 1px solid $primary;
          border-radius: 50%; // make it round
          background: #EBEEF5;
          position: relative;
          outline: none;
          &:checked {
            background: $primary;
          }
        
          &:checked::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            background: $white;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .content-checkbox{
          color: #001965;
          font-size: 16px;
          font-family: Apis;
          font-weight: 700;
          word-wrap: break-word;
          position: relative;
          display: inline-block;
        }
      }
      .modal-close-container{
        width: 100%; 
        position: relative;
        height: 40px;
        .modal-close-button{
          right: 0px; 
          top: 0px; 
          height: 35px;
          width: 35px;
          font-size: 30px;
          position: absolute;
          color: $primary;
        }
      }
    }
  }


  .onboard{
    .onboarding-container{
      width: 100%; 
      height: 100%; 
      padding-top: 158px; 
      padding-bottom: 140px; 
      padding-left: 30px; 
      padding-right: 29px; 
      background: white; 
      flex-direction: column; 
      justify-content: flex-end; 
      align-items: center; 
      display: inline-flex;

      .onboarding-content{
        align-self: stretch; 
        // height: 634px; 
        padding: 32px; 
        background: white; 
        border-radius: 15px; 
        flex-direction: column; 
        justify-content: center; 
        align-items: center; 
        gap: 64px; 
        display: inline-flex;
        max-width: 800px;
        margin: auto;

        img.box-rotate{
          transform: rotate(15deg);
        }

        .onboarding-logo{
          width: 80px; 
          height: 80px; 
          position: relative;

          .onboarding-logo-image{
            width: 80px; 
            height: 80px; 
            left: 0px; 
            top: 0px; 
            position: absolute;
          }
        }

        .head-tip-content{
          padding-left: 8px; 
          padding-right: 8px; 
          padding-top: 4px; 
          padding-bottom: 4px; 
          background: #001965; 
          border-radius: 16px; 
          justify-content: center; 
          align-items: center; 
          gap: 8px; 
          display: inline-flex;
          color: $white;
        }

        .header{
          width: 100%;  
          
          // justify-content: center; 
          // align-items: center; 
          gap: 28px; 
          display: inline-flex;
          .header-left{
            width: 24px; 
            height: 24px; 
            justify-content: center; 
            align-items: center; 
            display: flex;
            .header-back-button{
              padding: 10px 20px; 
              border-radius: 16px; 
              border: 1px #939AA7 solid; 
              position: relative; 
              flex-direction: column; 
              justify-content: flex-start; 
              align-items: flex-start; 
              display: flex;
            }
          }
          
  
        }

        
        

        .onboarding-carousel-content{
          align-self: stretch; 
          padding: 12px; 
          background: white; 
          border-top-left-radius: 15px; 
          border-top-right-radius: 15px; 
          flex-direction: column; 
          justify-content: flex-start; 
          align-items: center; 
          display: flex;
          .carousel-image-container{
            align-self: stretch; 
            height: 206px; 
            flex-direction: column; 
            justify-content: flex-start; 
            align-items: center; 
            display: flex;
            position: relative;
            .image{
              width: 100%;
              height: 100%;
              max-width: 232px; 
              max-height: 232px
            }
            .image-background{
              align-self: stretch; 
              background: #FCF2F5;
              position: absolute;
              top: 20px;
              left: 0;
              right: 0;
              bottom: 0;
            }
            .image-background-blue{
              background: #D9E6F8;
              align-self: stretch; 
              position: absolute;
              top: 20px;
              left: 0;
              right: 0;
              bottom: 0;
            }
            .image-background-yellow{
              background: #FCF3D9;
              align-self: stretch; 
              position: absolute;
              top: 20px;
              left: 0;
              right: 0;
              bottom: 0;
            }
            .image-background-green{
              background: #DFEFEE;
              align-self: stretch; 
              position: absolute;
              top: 20px;
              left: 0;
              right: 0;
              bottom: 0;
            }
            .image-background-novoamigo{
              background: #DFEFEE;
              align-self: stretch; 
              position: absolute;
              top: 0px;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 12px;
            }
          }
          .text-subcontainer{
            align-self: stretch; 
            height: 188px; 
            padding: 12px; 
            background: white; 
            border-top-left-radius: 15px; 
            border-top-right-radius: 15px; 
            flex-direction: column; 
            justify-content: flex-start; 
            align-items: center; 
            display: flex;
            .text-container{
              align-self: stretch; 
              text-align: center;
              color: $primary; 
              font-size: 16px; 
              font-family: Apis; 
              font-weight: 400; 
              word-wrap: break-word;
            }
          }
          .button-container{
            align-self: stretch; 
            padding-top: 20px; 
            justify-content: space-between; 
            align-items: center; 
            display: inline-flex;

          }
          .progress-container{
            height: 8px; 
            padding-left: 8px; 
            padding-right: 8px; 
            flex-direction: column; 
            justify-content: center; 
            align-items: center; 
            gap: 10px; 
            display: inline-flex;
            .progress-inner{
              padding-left: 4px; 
              padding-right: 4px; 
              justify-content: center; 
              align-items: center; 
              gap: 8px; 
              display: inline-flex;
              .progress-bar{
                width: 100%; 
                height: 100%; 
                position: relative;
                .progress-bar-inner{
                  width: 32px; 
                  height: 8px; 
                  left: 0px; 
                  top: 0px; 
                  background: #001965; 
                  border-radius: 15px;
                }
              }
              .progress-dot{
                width: 8px; 
                height: 8px; 
                position: relative;
                .progress-dot-inner{
                  width: 8px; 
                  height: 8px; 
                  left: 0px; 
                  top: 0px; 
                  background: #B7C0DB; 
                  border-radius: 9999px;
                }
              }
            }
          }
          
        }

        .onboard-agreement-links{
          flex-direction: column; 
          justify-content: center; 
          align-items: center; 
          gap: 24px; 
          display: flex;
          .onboard-agreement-link-item{
            height: 29px; 
            justify-content: flex-start; 
            align-items: center; 
            gap: 12px; 
            display: inline-flex;
            font-size: 16px;
            .icon i{
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .scale-weight-container{
    height: 150px;
    position: relative;
    .scale-weight-slider{
      // height: 200px; 
      position: absolute; 
      top: 0; 
      bottom: 0; 
      left: 0; 
      right: 0; 
      width: 100%;
      // margin-top: 25px;
      .icon-ruler-top{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        font-size: 24px;
        z-index: 3;
        color: $primary;
        margin: auto;
      }
      .icon-ruler-bottom{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 24px;
        z-index: 3;
        color: $primary;
        margin: auto;
      }
    }
  }

  .scale-height-container{
    height: 250px;
    position: relative;
    .scale-height-slider{
      // height: 200px; 
      position: absolute; 
      top: 0; 
      bottom: 0; 
      left: 0; 
      right: 0; 
      width: 100px;
      margin-left: auto;
      margin-right: auto;
      height: 200px;
      .icon-ruler-left{
        position: absolute;
        top: 10px;
        left: -24px;
        font-size: 24px;
        z-index: 3;
        color: $primary;
      }
      .icon-ruler-right{
        position: absolute;
        top: 10px;
        right: -24px;
        font-size: 24px;
        z-index: 3;
        color: $primary;
      }
    }
  }

  .title-primary{
    // Otimismo que
    color: $primary;
    font-size: 32px;
    font-family: Apis;
    font-weight: 400;
    line-height: 31px;
    word-wrap: break-word

  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .scale-input{
    display: inline;
    width: 75px;
    border: none;
    color: $primary;
    font-size: 36px;
    line-height: 32.72px;
    word-wrap: break-word;
    text-align: right;
  }
  
}