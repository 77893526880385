.story-modal{
  .delete{
    position: absolute;
    right: 1rem;
    top: 20px;
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
  .modal-next-link{
    position: absolute;
    right: 0;
    top: 30%;
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
  .modal-prev-link{
    position: absolute;
    left: 0;
    top: 30%;
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
  .story-modal-image-container{
    position: relative;
    margin-bottom: 2rem;
    .story-icon{
      position: absolute;
      right: 1rem;
      bottom: -36px;
    }
    .has-background-image{
      filter: blur(8px) brightness(0.5);
      -webkit-filter: blur(8px) brightness(0.5);
      -moz-filter: blur(8px) brightness(0.5);
      -o-filter: blur(8px) brightness(0.5);
      -ms-filter: blur(8px) brightness(0.5);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .image{
      max-height: 300px;
      text-align: center;
      img, svg{
        max-height: 300px;
        width: auto;
        margin: auto;
      }
    }
  }
  .story-modal-content-container{
    .users{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .avatars{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .participant{
          display: block;
          height: 48px;
          width: 48px;
          font-size: .8rem;
          border-radius: 50%;
          border: 4px solid #fff;
          margin-left: -14px;
          &:not(:first-child) {
            margin-left: -14px;
          }
          &.is-fake{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 52px;
            width: 52px;
            font-weight: 700;
            font-size: 1rem;
            color: #fff;

            &.is-count{
              background: #f0f0f0;
              color: #283252;
            }
          }
        }
      }
    }
  }
  .story-modal-action-container{
    background: $light;
    position: absolute;
    z-index: 10;
    top: 5rem;
    bottom: 0;
    width: 100%;
    left: 0;
    box-shadow: 0px -20px 50px rgba(0, 0, 0, 0.08);
    border: $grey-lighter 1px solid;
    border-radius: 16px;
    // overflow: auto;
    padding-bottom: 100px;
    .close-comments {
      position: absolute;
      right: 1rem;
      top: 1rem;
      max-width: 50px;
      max-height: 50px;
      z-index: 1;
      user-select: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: rgba(32, 32, 32, 0.2);
      border: none;
      border-radius: 9999px;
      cursor: pointer;
      pointer-events: auto;
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 0;
      height: 20px;
      width: 20px;
      max-height: 20px;
      max-width: 20px;
      min-height: 20px;
      min-width: 20px;
      outline: none;
      vertical-align: top;
      &::before, &::after {
        background-color: #fff;
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center;
      }
      &::before {
        height: 2px;
        width: 50%;
      }
      &::after{
        height: 50%;
        width: 2px;
      }
    }
    .story-modal-comments-list{
      overflow: auto;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: 100px;
    }
    .story-modal-comment-new{
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1rem;
      background: $white;
      box-shadow: 0px -20px 50px rgba(0, 0, 0, 0.05);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      .field{
        margin-bottom: 0;
      }
    }
  }
}
