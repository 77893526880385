/*! _animations.scss | Huro | Css ninja 2020-2021 */

/*
    1. Base Class
    2. pulsate
    3. Infinite rotate
    4. Fade In Up
    5. Fade In Down
    6. Fade In Left
    7. Scale in
    8. Scale out
    9. Scale In Circle
    10. Gelatine
    11. Wave
    12. Levitate
    13. Glowing
    14. Translate Left
*/


/* ==========================================================================
1. Base Class
========================================================================== */

.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

/* ==========================================================================
2. Pulsate
========================================================================== */

@keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0.0;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0.0;
    }
}

.pulsate {
    animation: pulsate 1.5s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
}

/* ==========================================================================
3. Infinite rotate
========================================================================== */

@keyframes rotating {
    from {
        transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotating {
    from {
        transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

/* ==========================================================================
4. Fade In Up
========================================================================== */

@-webkit-keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.preFadeInUp {
    opacity: 0;
}

.fadeInUp {
    opacity: 0;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

/* ==========================================================================
5. Fade In Down
========================================================================== */

@-webkit-keyframes fadeInDown {
    from {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.preFadeInDown {
    opacity: 0;
}

.fadeInDown {
    opacity: 0;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

/* ==========================================================================
6. Fade In Left
========================================================================== */

@-webkit-keyframes fadeInLeft {
    from {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    from {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.preFadeInLeft {
    opacity: 0;
}

.fadeInLeft {
    opacity: 0;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

/* ==========================================================================
7. Scale in
========================================================================== */

@-webkit-keyframes scaleIn {
    from {
        -webkit-transform: scale(0);
    }

    to {
        -webkit-transform: scale(1);
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.scaleIn {
    -webkit-animation: scaleIn 0.5s;
    animation: scaleIn 0.5s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.scaleInTooltip {
    -webkit-animation: scaleIn 0.3s;
    animation: scaleIn 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* ==========================================================================
8. Scale out
========================================================================== */

@-webkit-keyframes scaleOut {
    from {
        -webkit-transform: scale(1);
    }

    to {
        -webkit-transform: scale(0);
    }
}

@keyframes scaleOut {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(0);
    }
}

.scaleOut {
    -webkit-animation: scaleOut 0.5s;
    animation: scaleOut 0.5s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.scaleOutTooltip {
    -webkit-animation: scaleOut 0.3s;
    animation: scaleOut 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* ==========================================================================
9. Scale in circle (used for modal popup)
========================================================================== */

@-webkit-keyframes scaleInCircle {
    from {
        -webkit-transform: scale(0);
    }

    to {
        -webkit-transform: scale(7);
    }
}

@keyframes scaleInCircle {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(7);
    }
}

.scaleInCircle {
    -webkit-animation: scaleInCircle 0.6s;
    animation: scaleInCircle 0.6s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* ==========================================================================
10. Gelatine
========================================================================== */

@-webkit-keyframes gelatine {
    from, to {
        -webkit-transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.9, 1.1);
    }

    50% {
        -webkit-transform: scale(1.1, 0.9);
    }

    75% {
        -webkit-transform: scale(0.95, 1.05);
    }
}

@keyframes gelatine {
    from, to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(0.9, 1.1);
    }

    50% {
        transform: scale(1.1, 0.9);
    }

    75% {
        transform: scale(0.95, 1.05);
    }
}

.gelatine {
    -webkit-animation: gelatine 0.6s;
    animation: gelatine 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* ==========================================================================
11. Wave
========================================================================== */

@keyframes wave {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    25% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(4.5);
        opacity: 0;
    }
}

/* ==========================================================================
12. Levitate
========================================================================== */

@-webkit-keyframes levitate {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px);
    }

    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@keyframes levitate {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px);
    }

    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.levitate {
    -webkit-animation-name: levitate;
    animation-name: levitate;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

/* ==========================================================================
13. Glowing
========================================================================== */

@keyframes glowing {
    0% {
        box-shadow: 0 0 -10px #fafafa;
    }

    40% {
        box-shadow: 0 0 20px #fafafa;
    }

    60% {
        box-shadow: 0 0 20px #fafafa;
    }

    100% {
        box-shadow: 0 0 -10px #fafafa;
    }
}

/* ==========================================================================
14. Translate Left
========================================================================== */

@-webkit-keyframes translateLeft {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes translateLeft {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.translateLeft {
    -webkit-animation-name: translateLeft;
    animation-name: translateLeft;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-transition: all 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition: all 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
