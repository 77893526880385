// @import "custom-variables";
@import "skeleton-screen-css";
body.community{
  turbo-frame[aria-busy="true"]{
    > .show-when-aria-busy{
      display: block;
    }
    > .hide-when-aria-busy{
      display: none;
    }
  }
  turbo-frame:not([aria-busy="true"]){
    > .show-when-aria-busy{
      display: none;
    }
    > .hide-when-aria-busy{
      display: block;
    }
  }
  &:not(.onboard).mobile-focus {
    @media (min-width: $tablet) {
      padding-top: 100px !important;
    }
    padding-top: 0px;
  }
  &:not(.onboard):not(.mobile-focus) {
    @media (min-width: $tablet) {
      padding-top: 100px !important;
    }
    padding-top: 60px;
  }
  @media (max-width: $tablet) {
    section.hero .hero-body {
      padding: 1.5rem 1.5rem;
    }
  }
  .referral-url{
    white-space: normal;
    height: auto;
  }
  hr{
    background-color: $grey-lightest;
  }
  .navbar{
    background-color: $white;
  }
  .navbar-burger {
    color: $purple;
    span {
      height: 2px;
    }
  }
  .navbar-menu-admin-mobile{
    a {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1 1 0px;
      text-align: center;
      padding: 10px 0;
      border-radius: 8px;
      border: 1px solid transparent;
      margin: 0px 4px;
      transition: all 0.3s;
      height: 100%;
      .menu-label{
        font-size: 0.6rem;
      }
      &.is-active{
        background: #fff;
        border-color: #e5e5e5;
        box-shadow: -1px 3px 10px 0 rgb(0 0 0 / 6%);
        div, span { color: $primary !important; }
      }
    }
  }
  .tags.textarea{
    height: auto;
    min-height: 0;
  }
  .taggle_list{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    .tag:not(body) .delete {
      margin-left: 0.25rem;
      margin-right: -0.375rem;
      margin-top: 0.25rem;
    }
    li{
      // margin-right: 0.5rem;
      // margin-bottom: 0.5rem;
      display: block;
    }
    .taggle_input{
      margin-bottom: 0.5rem;
      width: auto !important;
      border: none;
      font-size: 1rem;
      &:active, &:focus, &:focus-visible, &:hover{
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
  .taggle_sizer{
    display: none;
  }
  .flex-table-cell.handle{
    cursor: move;
  }
  .components-pricing{
    position: relative;
    .delete-icon{
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }
  .progress-circle{
    .progress-container{
      width: 100%;
      max-width: 150px;
      position: relative;
      margin: auto;
    }
  }
  progress.is-small{
    height: .35rem !important;
  }
  .progress.is-acqua::-webkit-progress-value {
    background-color: rgba($color: $acqua, $alpha: 1.0)
  }
  .progress.is-acqua::-moz-progress-bar {
    background-color:rgba($color: $acqua, $alpha: 1.0)
  }
  .progress.is-acqua::-ms-fill {
    background-color:rgba($color: $acqua, $alpha: 1.0)
  }
  .progress.is-acqua:indeterminate {
    background-image: linear-gradient(to right, rgba($color: $acqua, $alpha: 1.0) 30%, #ededed 30%);
  }
  .message .title.title-link a{
    text-decoration-color: transparent;
    &:hover {
      text-decoration-color: currentColor;
    }
  }
  .right-panel-wrapper{
    &.is-activity .right-panel .right-panel-body{
      height: calc(100% - 60px);
    }
    .tabs-content{
      .button {
        text-size-adjust: 100%;
        box-sizing: border-box;
        outline: none;
        // font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 10px 0px;
        overflow: visible;
        text-transform: none;
        color: inherit;
        border-width: 0px;
        padding: 0px;
        cursor: pointer;
        appearance: none;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        z-index: 198;
        flex-direction: row;
        background-color: $orange;
  
        &.is-success{
          background-color: $green;
        }
        // box-shadow: rgba(100, 100, 100, 0.28) 0px 0px 29px;
        border-radius: 40px;
        transition: flex 1s ease-in-out 0s;
        height: 64px;
        width: 100%;
        // padding-right: 20px;
  
        .button-container {
          width: 100%;
          height: 100%;
          opacity: 1;
  
          .button-progress-container {
            position: relative;
            top: -1px;
          }
  
          .button-text-container {
            -webkit-box-align: center;
            align-items: center;
            flex-direction: row;
            display: flex;
            height: 100%;
  
            &:hover {
              .button-timer {
                animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
              }
            }
  
            .button-progress {
              margin-left: 4px;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              display: flex;
              height: 58px;
              min-width: 58px;
  
              .button-percentage {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                font-weight: 600;
                color: rgb(19, 19, 19);
                flex: 0 0 auto;
                background-color: transparent;
                font-size: 1rem;
                padding-left: 1px;
              }
            }
  
            .button-text {
              margin-left: 10px;
              font-size: 1rem;
              font-weight: 600;
              line-height: 1.5;
              position: relative;
              top: -1px;
              // white-space: nowrap;
              flex-basis: 0;
              flex-grow: 1;
              flex-shrink: 1;
              overflow: hidden;
            }
  
            .button-timer {
              margin-right: 4px;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              display: flex;
              height: 58px;
              min-width: 45px;
            }
          }
        }
      }
    }
    
  }
  .quests-float{
    color: $dark-text;
    box-sizing: border-box;
    outline: none;
    position: fixed;
    z-index: 99;
    bottom: 32px;
    left: 24px;
    .button {
      text-size-adjust: 100%;
      box-sizing: border-box;
      outline: none;
      font-family: inherit;
      font-size: 100%;
      line-height: 1.15;
      margin: 0px;
      overflow: visible;
      text-transform: none;
      color: inherit;
      border-width: 0px;
      padding: 0px;
      cursor: pointer;
      appearance: none;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      z-index: 198;
      flex-direction: row;
      background-color: $orange;
      box-shadow: rgba(100, 100, 100, 0.28) 0px 0px 29px;
      border-radius: 40px;
      transition: flex 1s ease-in-out 0s;
      height: 64px;
      width: auto;
      padding-right: 5px;

      .button-container {
        width: 100%;
        height: 100%;
        opacity: 1;

        .button-progress-container {
          position: relative;
          top: -1px;
        }

        .button-text-container {
          -webkit-box-align: center;
          align-items: center;
          flex-direction: row;
          display: flex;
          height: 100%;

          .button-progress {
            margin-left: 4px;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            display: flex;
            height: 58px;
            min-width: 58px;

            .button-percentage {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              font-weight: 600;
              color: rgb(19, 19, 19);
              flex: 0 0 auto;
              background-color: transparent;
              font-size: 1rem;
              padding-left: 1px;
            }
          }

          .button-text {
            margin-left: 10px;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.5;
            position: relative;
            top: -1px;
            white-space: nowrap;
            margin-right: 10px;
            @media (max-width: $tablet) {
              display: none;
            }
          }
        }
      }
    }
  }
  .nowrap{
    white-space: nowrap;
  }
  .prewrap{
    white-space: pre-wrap;
  }
  .button.is-add{
    height: 40px;
    width: 40px;
    font-size: 13px;
  }
  .card.is-flex{
    flex-direction: column;
    height: 100%;
    .card-footer{
      margin-top: auto;
    }
  }
  .suggested-carousel{
    ul{
      justify-content: center;
      width: 100%;
    }
  }
  .journey-container{
    .long-card {
      height: 100%;
    }
    .locked{
      position: absolute;
      background: #00000057;
      height: 100%;
      width: 100%;
      border-radius: 16px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      span.icon{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        color: $white;
        font-size: 50pt;
      }
    }
  }
  .swiper {
    &.journey-swiper{
      height: 100%;
      .swiper-slide{
        height: auto;
      }
    }
    margin-bottom: 1.5rem;
    .swiper-slide-carousel{
      max-width: 200px;
      &.slide-goal{
        max-width: none;
      }
    }
    .long-card .long-card-text-overlay{
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    .swiper-button-next, .swiper-button-prev {
      color: $primary;
      &:after{
        text-shadow: 0px 0px 10px #fff, 0 0 10px #ccc;
      }
    }
    .swiper-slide{
      .long-card{
        width: 200px;
        height: 300px;
        padding: 0;
      }
      @media(min-width: $tablet) {
        &.hover-grow{
          transition: all 0.3s;
          &:hover{
            width: 300px;
            // height: 300px;
            max-width: none;
            .long-card.has-background-image{
              width: 300px;
              height: 300px;
              max-width: none;
            }
          }
          .long-card.has-background-image:hover{
            width: 300px;
            height: 300px;
            max-width: none;
          }
        }
      }
    }
    
  }
  .slider{
    text-align: center;
    overflow: hidden;
    .slider-navigation-next, .slider-navigation-previous{
      position: absolute;
      transform: translateY(-50%);
    }
    .slider-container {
      
      padding-bottom: 20px;
      margin: auto;
      .slider-item{
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  // .analytics-dashboard{
  //   .column {
  //     min-width: 33%;
  //   }
  // }
  .creators-section {
    padding-bottom: 0;
    padding-top: 1rem;
    .slider .slider-container{
      padding-bottom: 0;
    }
    .column-add-creator {
      opacity: 0.5;
      .add-creator-circle {
        border-radius: 50%;
        border: 3px solid $info;
        
        img {
          opacity: 0.5;
        }
        .icon {
          color: $info;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          font-size: 20px;
        }
      }
    }
    
  }
  .feed-section, .creators-section{
    .slider{
      .slider-container {
        margin: unset;
      }
    }
  }
  .carousel-left{
    .slider{
      text-align: left;
      .slider-container {
        margin: 0;
      }
    }
  }
  .overview-card {
    .card-metric {
      margin-top: auto;
    }
  }
  .profile-card{
    position: relative;
    // height: 270px;
    // width: 450px;
    border-radius: 0.25rem;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);  /* This will produce the blur */
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
    padding: 20px 20px 20px 20px;
    width: 100%;
    // overflow: hidden;
    .profile-avatar{
      img{
        border: 3px solid $info;
        transition: all 0.1s linear;
        transition-property: border;
        &:hover {
          border: 1px solid $primary;
        }
      }
    }
    
  }

  .nft-label, .nft-media {
    border-radius: 10px;
    display: inline-block;
    padding: 3px;
    text-decoration: none;
    font-size: 12px;
    width: auto;
    &.is-small{
      font-size: 10px;
    }

    span {
      background: #fff;
      display: block;
      padding: 5px 20px;
      border-radius: 7px;
      font-weight: 500;
      line-height: 15px;
      width: 100px;
      text-align: center;
      color: $black;
      
    }

    img{
      background: #fff;
      border-radius: 10px !important;
    }

    &.nft-tag {
      background-image: linear-gradient(90deg, #FBFBFB 0%, #EAEAEA 100%);
      border: #EBEBEB 3px solid;
      padding: 5px 20px;
      color: $black;
      font-weight: 500;
      border-radius: 10px;
      line-height: 15px;
      width: 106px;
      text-align: center;
      &.is-small{
        font-size: 10px;
      }
    }

    &.nft-unicorn {
      background: linear-gradient(207.04deg, #2958FA 1.33%, rgba(154, 27, 217, 1) 30.17%, #E2B30D 54.45%, rgba(247, 251, 66, 1) 74.68%, #11C2FA 98.45%);
      color: $white;
    }
    &.nft-epic {
      background: linear-gradient(207.04deg, rgba(79, 250, 229, 1) 1.33%, #43DCFE 46.86%, rgba(116, 247, 230, 1) 98.45%);
      color: $white;
    }
    &.nft-rare {
      background: linear-gradient(207.49deg, rgba(162, 215, 94, 1) 0%, #48CCAC 53.65%, rgba(162, 215, 94, 1) 100%);
      color: $white;
    }
    &.nft-special {
      background: linear-gradient(207.04deg, #F3C1C1 1.33%, rgba(209, 86, 59, 1) 29.66%, #FA5E5E 64.06%, #FFB067 98.45%);
      color: $white;
    }
    &.nft-common {
      background: linear-gradient(207.04deg, #E2E2E2 1.33%, #F4F4F4 46.86%, #DEDEDE 98.45%);
      color: $black;
    }
  }
  
  .reward-card, .item-card{
    &.item-card-horizontal{
      .image img:not(.avatar){
        border-radius: 0px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        @media(max-width: $tablet) {
          border-radius: 0;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }
      }
      
      .item-card-info{
        padding: 35px;
      }
      
      .item-token-tag{
        position: absolute;
        right: 0;
        top:0;
        border-radius: 0;
      }

    }
    border-radius: 16px;
    .image img:not(.avatar) {
      border-radius: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .item-card-nft-container div{
      @media(max-width: $tablet) {
        display: inline-block;
      }
    }
    .item-nft-tag-image{
      position: absolute;
      left: 5px;
      bottom: 5px;
    }
    
    
    .users{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .avatars{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .participant{
          display: block;
          height: 48px;
          width: 48px;
          font-size: .8rem;
          border-radius: 50%;
          border: 4px solid #fff;
          &:not(:first-child) {
            margin-left: -14px;
          }
          &.is-fake{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 52px;
            width: 52px;
            font-weight: 700;
            font-size: 1rem;
            color: #fff;

            &.is-count{
              background: #f0f0f0;
              color: #283252;
            }
          }
        }
      }
    }
  }
  .user-items-section{
    .user-items-header{
      .title{
        // border-bottom-color: #dbdbdb;
        // border-bottom-style: solid;
        // border-bottom-width: 1px;
        // margin-bottom: 0;
      }
    }
  }
  .long-card{
    .nft-label.nft-tag{
      width: auto;
      padding: 1px 10px;
      border-radius: 5px;
    }
    .long-card-icon{
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
  .card.nft-info-card{
    border-radius: 16px;
    width: 100%;
    padding: 35px;
    .nft-token-tag{
      position: absolute;
      right: 0;
      top:0;
      border-radius: 0;
    }
    .avatars a{
      display: inline-block;
    }
  }
  .card.nft-card{
    padding: 35px;
    &:hover{
      animation: tada; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 1s; /* don't forget to set a duration! */
      --animate-repeat: 1;
    }
    &.nft-rarity-card{
      border-radius: 10px;
      // display: inline-block;
      padding: 3px;
      text-decoration: none;
      font-size: 12px;

      > div {
        background: $white-bis;
        display: block;
        padding: 35px;
        border-radius: 7px;
        height: 100%;
      }
      .card-content{
        background-color: $white;
      }

      &.nft-unicorn {
        background: linear-gradient(207.04deg, #2958FA 1.33%, rgba(154, 27, 217, 1) 30.17%, #E2B30D 54.45%, rgba(247, 251, 66, 1) 74.68%, #11C2FA 98.45%);
        color: $white;
      }
      &.nft-epic {
        background: linear-gradient(207.04deg, rgba(79, 250, 229, 1) 1.33%, #43DCFE 46.86%, rgba(116, 247, 230, 1) 98.45%);
        color: $white;
      }
      &.nft-rare {
        background: linear-gradient(207.49deg, rgba(162, 215, 94, 1) 0%, #48CCAC 53.65%, rgba(162, 215, 94, 1) 100%);
        color: $white;
      }
      &.nft-special {
        background: linear-gradient(207.04deg, #F3C1C1 1.33%, rgba(209, 86, 59, 1) 29.66%, #FA5E5E 64.06%, #FFB067 98.45%);
        color: $white;
      }
      &.nft-common {
        background: linear-gradient(207.04deg, #E2E2E2 1.33%, #F4F4F4 46.86%, #DEDEDE 98.45%);
        color: $black;
      }
    }
    .title{
      font-size: 1rem;
    }
    .card-image{
      .image img{
        border-radius: 0.25rem;
      }
    }
    .nft-label.nft-tag{
      width: 86px;
    }
    .nft-label span{
      width: 80px;
      padding: 5px 5px;
    }
    .nft-media {
      border-radius: 0.25rem !important;
      img {
        border-radius: 0.25rem !important;
        background-color: $white;
      }
    }
  }
  .card.user-item-card{
    padding: 35px;
    background: $white-bis;
    .title{
      font-size: 1rem;
    }
    .card-content{
      background: $white;
    }
    .card-image{
      .image {
        margin-bottom: 20px;
        img{
          border-radius: 0.25rem;
          background-color: $white;
        }
      }
      .user-item-logo{
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: -20px;
        border: 4px solid $white-bis;
        border-radius: 0.25rem;
      }
      
    }
  }
  .currency-price,.currency-price .title{
    color: $orange;
  }
  .credits-price,.credits-price .title{
    color: $green;
  }
  .current-xp,.current-xp .title{
    color: $purple;
  }
  .has-ranking-myself{
    background-color: $purple-light;
  }
  .control.has-icons-right .icon.has-tooltip{
    pointer-events: auto !important;
  }
  .supporter-border-primary{
    border-radius: 50%;
    border: 3px solid $primary;
    .supporter-icon{
      background-color: $primary;
    }
  }
  
  .supporter-border-success{
    border-radius: 50%;
    border: 3px solid $success;
    .supporter-icon{
      background-color: $success;
    }
  }
  .supporter-border-light{
    border-radius: 50%;
    border: 3px solid $light;
    .supporter-icon{
      background-color: $light;
    }
  }
  .tag.is-admin{
    background-color: $admin-color;
    color: $white;
  }
  .tag.is-creator{
    background-color: $creator-color;
    color: $white;
  }
  .tag.is-premium{
    background-color: $premium-color;
    color: $dark;
    &.is-light{
      background-color: $premium-color-lighter;
      color: $dark;
    }
  }
  .tag.is-vip{
    background-color: $vip-color;
    color: $dark;
    &.is-light{
      background-color: $vip-color-lighter;
      color: $vip-color-darker;
    }
    
  }
  .tag.is-premium-vip{
    background-color: $vip-color;
    color: $dark;
    &.is-light{
      background-color: $premium-vip-color-lighter;
      color: $vip-color-darker;
    }
    
  }

  .message.is-premium{
    .message-body{
      border-color: $premium-color;
    }
  }
  .message.is-vip{
    .message-body{
      border-color: $vip-color;
    }
  }
  .message.is-premium-vip{
    .message-body{
      border-color: $premium-vip-color;
    }
  }

  .notification.is-premium{
    background-color: $premium-color-lighter;
    color: $premium-color-darker;
  }
  .notification.is-vip{
    background-color: $vip-color-lighter;
    color: $vip-color-darker;
  }
  .notification.is-premium-vip{
    background-color: $premium-vip-color-lighter;
    color: $premium-vip-color-darker;
  }

  .supporter-border-premium {
    border-radius: 50%;
    border: 3px solid $premium-color;
    .supporter-icon{
      border: $premium-color 2px solid;
      background-color: $premium-color-dark;
      color: $dark;
    }
  }
  .supporter-border-premium-vip {
    border-radius: 50%;
    border: 3px solid $premium-color;
    .supporter-icon{
      padding-top: 4px;
      border: $premium-color 2px solid;
      background-color: $premium-color-dark;
      color: $dark;
    }
  }
  .supporter-border-vip{
    border-radius: 50%;
    border: 3px solid $vip-color;
    .supporter-icon{
      padding-top: 4px;
      border: $vip-color 2px solid;
      background-color: $vip-color-dark;
      color: $dark;
    }
  }
  .supporter-border-creator{
    border-radius: 50%;
    border: 3px solid $creator-color;
    .supporter-icon.is-premium{
      border: $premium-color 2px solid;
      background-color: $premium-color-dark;
      color: $dark;
    }
    .supporter-icon.is-premium-vip{
      padding-top: 4px;
      border: $premium-color 2px solid;
      background-color: $premium-color-dark;
      color: $dark;
    }
    .supporter-icon.is-vip{
      padding-top: 4px;
      border: $vip-color 2px solid;
      background-color: $vip-color-dark;
      color: $dark;
    }
  }
  .supporter-border-admin{
    border-radius: 50%;
    border: 3px solid $admin-color;
    .supporter-icon{
      background-color: $admin-color;
      color: $white;
    }
  }
  .supporter-icon{
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 2px;
  }
  .leaderboard-podium{
    figure{
      max-width: 100%;
      height: auto;
      img {
        max-width: 100%;
      }
    }
    .icon-crown{
      font-weight: bold;
      color: $orange;
      margin-bottom: -5px;
    }
    .leaderboard-username {
      overflow: hidden;
    }
    .leaderboard-column{
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      .leaderboard-content{
        margin-top: auto;
      }
    }
    .leaderboard-first{
      width: 40% !important;
      figure {
        border-radius: 50%;
        border: 3px solid $orange;
        padding: 2px;
      }
    }
    .leaderboard-second{
      width: 30% !important;
      // padding-top: 35px;
    }
    .leaderboard-third{
      width: 30% !important;  
    }
  }
  .creators-list{
    @media screen and (max-width: $tablet) {
      // justify-content: center;
    }
    figure{
      padding: 4px;
    }
    .is-live{
      position: absolute;
      left: 50%;
      transform: translate(-50%, -75%);
      background-color: $color-youtube;
      color: $white;
      font-weight: bold;
    }
    .is-offline{
      position: absolute;
      left: 50%;
      transform: translate(-50%, -75%);
      background-color: $light;
      color: $dark;
      font-weight: bold;
    }
    .is-new{
      position: absolute;
      left: 50%;
      transform: translate(-50%, -75%);
      background-color: $creator-color;
      color: $white;
      font-weight: bold;
    }
  }
  .navbar-divider{
    margin: 0;
    padding: 0;
  }
  .dropdown-content{
    margin:0;
    padding: 0;
  }
  .navbar-secondary-color{
    background-color: $yellow;
    color: $black;
  }
  .navbar-dark-color{
    background-color: $black;
    color: $white;
    min-height: 100vh;
    .menu-list a{
      color: $white;
    }
  }
  .section-live{
    .twitch .twitch-video {
      padding-top: 56.25%;
      position: relative;
      height: 0;
    }
    .twitch .twitch-video iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
    .twitch .twitch-chat {
      height: 450px;
      .tabs-wrapper{
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        .tabs{
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .tabs-content{
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: auto;
          ul, li {
            flex: 1;
            height: 100%;
          }
          .leaderboard{
            .column{
              padding: 0.5rem;
            }
          }
        }
      }
    }
    .twitch .twitch-chat iframe {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 850px) {
      .twitch {
        position: relative;
      }
      .twitch .twitch-video {
        width: 75%;
        padding-top: 42.1875%;
      }
      .twitch .twitch-chat {
        width: 25%;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  @media screen and (max-width: 768px) {
    #menu-toggle:checked + .nav-menu {
      display: block;
    }
  }
  .onboard-split{
    .bg-split-content {
      .bg-split-color-content {
        background-color: $white;
      }
      .box {
        position: relative;
      }
      form{
        padding: 25px;
      }
      .hero-body{
        padding: 1rem;
      }
      .onboard-discord-text-image{
        max-width: 200px;
      }
    }
  }
  .form-container{
    // margin-top: 10px !important;
    max-width: $tablet;
    margin: auto;
    input.community_input_community_url{
      padding-left: 130px;
    }
    .community_url_domain{
      position: absolute;
      left: 5px;
      top: 12px;
      z-index: 2;
      font-size: 12px;
      color: $grey-light;
    }
    .community_coin_acronym{
      text-transform: uppercase;
    }
  }
  .box-avatar{
    padding-top: 40px;
    padding-left: 40px;
    .header{
      position: relative;
      .avatar{
        position: absolute;
        top: -100px;
        left: -100px;
      }
    }
  }
  .pricing-plan{
    max-width: 400px;
  }
  .navbar-item img.avatar {
    max-height: initial;
  }
  .navbar-end, .navbar-start {
    a.navbar-item:not(button) {
      color: $purple;
      &:hover, &:active{
        background-color: $purple-light;
      }
    }
  }
  .navbar-brand {
    .brand-end, .brand-start {
      @media (max-width: $tablet) {
        width: auto;
      }
    }
  }
  .onboard-logo-footer-container{
    margin-left: 5px;
  }
  .navbar-item {
    .is-live{
      position: absolute;
      left: 50%;
      transform: translate(-50%, 100%);
      background-color: $color-youtube;
      color: $white;
      font-weight: bold;
    }
    .is-icon{
      width: 48px;
      height: 48px;
      max-height: 48px;
      &.is-live {
        border: 3px solid $color-youtube;
        width: 55px;
        height: 55px;
        max-height: 55px;
      }
      // margin-right: 0.5rem;
      @media (max-width: $tablet) {
        width: 32px;
        height: 32px;
        margin: 0;
      }
    }
  }
  // .steps:not(.is-hollow) .steps-marker:not(.is-hollow){
  //   background-color: $acqua;
  // }
  .activation-panel-space{
    height: 100px;
  }
  .activation-panel{
    box-shadow: 0px 12px 35px rgba(32, 32, 32, 0.15);

    position: fixed;
    bottom: 0;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: center;

    background: $yellow;
    &.is-active{
      background: $white;
      .activation-panel-header{
        .fa-chevron-up{
          display: none;
        }
        .fa-chevron-down{
          display: inline-block;
        }
      }
    }

    .steps.is-acqua:not(.is-hollow){
      .steps-segment.is-active {
        .steps-marker:not(.is-hollow){
          background-color: $acqua;
          color: $acqua-darker;
        }
      }
      .steps-marker:not(.is-hollow){
        background-color: $acqua;
        color: $acqua-darker;
      }
      .steps-segment:after{
        background-color: $acqua;
      }
      .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow) {
        background-color: $light;
        color: $dark;
      }
      .steps-segment.is-active ~ .steps-segment:after {
        background-color: $light;
      }
      .steps-segment.is-active:after {
        background-color: $light;
      }
    }

    @media(max-width: $tablet){
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }
    
    .activation-panel-header{
      padding: 15px;
      text-transform: uppercase;
      /* Preto-lighter */
      position: relative;
      color: #8D8D8E;
      padding-right: 20px;
      cursor: pointer;
      .dropdown{
        position: absolute;
        right: 10px;
        .dropdown-trigger{
          cursor: pointer;
        }
        .dropdown-content{
          .dropdown-item{
            text-transform: none;
            .icon-text{
              display: inline;
              span:not(.icon){
                display: table-caption;
              }
            }
          }
          a.dropdown-item{
            span:not(.icon){
              color: $primary;
              text-decoration: underline;
            }
          }
        }
      }
      .fa-chevron-up{
        display: inline-block;
      }
      .fa-chevron-down{
        display: none;
      }
    }
    .activation-panel-body{
      // padding: 15px;
      /* Preto-light */
      color: #727273;
      padding-bottom: 20px;
      display: none;
      a{
        font-weight: 500;
      }
    }
    &.is-active{
      .activation-panel-body{
        display: block !important;
      }
    }
  }
  .modal-admin-onboard{
    .modal-card{
      .modal-card-head{
        display: block;
        text-align: center;
        border-bottom: 1px solid #ededed;
        padding: 0 0 10px 0;
      }
      .modal-card-body{
        padding: 10%;
        .onboard-help-icon{
          position: absolute;
          top: 0;
          right: 0;
        }
        .onboard-coin-image{
          @media(max-width: 500px) {
            display: none;
          }
          position: relative;
          .onboard-coin-image-abbr{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;

            /* Amarelo-darker */
            color: #C8B92D;
          }
        }
      }

      @media (min-width: $tablet) {
        width: 80%;
        max-width: 800px;
      }
    }
  }
  .admin-onboard{
    background-color: $body-background-color;
    padding-bottom: 200px;
    text-align: center;
    .admin-onboard-content{
      display: inline-block;
      margin: auto;
    }
    .welcome-letter{
      font-family: 'Covered By Your Grace', cursive;
      // font-size: 2rem;
      font-size: 24px;
      font-weight: 100;
      line-height: 40px;
      padding: 1rem;
      text-align: left;
      color: $dark;
      .lines {
        // margin: 40px;
        height: calc(100% - 40px);
        width: 100%;
        background-image: repeating-linear-gradient($white 0px, $white 39px, steelblue 40px);
      }
      .text{
        margin-right: 2%;
        margin-left: 2%;
      }
    }
  }
  .pricing-card-container{
    .pricing-card-item{
      // padding: 30px;
      text-align: center;
      .card-image{
        img {
          border-radius: 0.25rem;
        }
      }
      .plan-description{
        color: $grey;
        font-size: 0.9rem;
      }
      .plan-price{
        color: $orange;
        font-size: 3rem;
        font-weight: bold;
        small{
          font-size: 1rem;
        }
      }
      .plan-credits{
        color: $success;
        font-size: 2rem;
        font-weight: bold;
        small{
          font-size: 1rem;
        }
      }
      .plan-items{
        .plan-item{
          color: $grey;
          font-size: 0.8rem;
          border: none;
          padding-top: 0.5rem;
        }
      }
    }
  }
  .modal {
    z-index: 100;

    &.is-big {
      .modal-card{
        width: 100%;
        max-width: 840px;
      }
    }

    &.is-large {
      .modal-card{
        width: 100%;
        max-width: 720px;
      }
    }

    &.is-medium {
      .modal-card{
        width: 100%;
        max-width: 640px;
      }
    }

    &.is-small {
      .modal-card{
        width: 100%;
        max-width: 420px;
      }
    }

  }
  .modal-card{
    // max-height: none;
    // .modal-card-body{
    // }
    

    border-radius: 16px;
    .modal-card-head, .modal-card-foot{
      background-color: $white;
    }
    .modal-card-head{
      border-bottom: none;
      border-top-left-radius: 1px;
      border-top-right-radius: 1px;
      .modal-card-title{
        flex-shrink:unset;
      }
    }
    .modal-card-foot:empty{
      border-top: none;
      display: none;
    }
    .modal-card-body{
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
    }
    .modal-card-foot{
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
    }
    .misssion-expiration-container{
      .space{
        position: relative;
        padding-top: 60px;
      }
      
      .notification {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }
  .checkout-container {

    .stripe-logo {
      max-width: 150px;
    }

    .card-logo {
      max-width: 35px;
    }

    .paypal-logo {
      max-width: 60px;
    }
    .box.is-active {
      border: $purple 1px solid;
    }
    .payment-option {
      height: 70px;
    }

    .card-form{
      max-width: 350px;
      margin: auto;

      .StripeElement--webkit-autofill {
        background: transparent !important;
      }
      
      .InputElement {
        background-color: red;
        width: 100%;
        padding: 11px 15px 11px 0;
      }
      .stripe-input{
        -webkit-font-smoothing: antialiased;
        text-size-adjust: 100%;
        box-sizing: inherit;
        margin: 0;
        font-family: "Montserrat", sans-serif;
        align-items: center;
        border: 1px solid transparent;
        font-size: 1rem;
        height: 2.5em;
        line-height: 1.5;
        padding-bottom: calc(0.75em - 1px);
        padding-left: calc(0.75em - 1px);
        padding-top: calc(0.75em - 1px);
        position: relative;
        vertical-align: top;
        background-color: white;
        border-color: #dbdbdb;
        border-radius: 4px;
        color: #363636;
        box-shadow: inset 0 0.0625em 0.125em rgba(32, 32, 32, 0.05);
        max-width: 100%;
        width: 100%;
        padding-right: calc(0.75em - 1px);
      }
      
    }
  }
  .checkout-confirmation-container{
    .box{
      max-width: 400px;
      margin: auto;
      .success-image{
        margin: auto;
        max-width: 300px;
      }
    }
  }
  .checkout-invoice-container{
    margin-bottom: 80px;
    .box{
      max-width: 500px;
      margin: auto;
    }
  }
  .redeem-content{
    .success-image{
      max-width: 300px;
      margin: auto;
    }
    .nft-label{
      width: auto;
      span{
        width: auto;
      }
    }
  }
  .comment-box{
    position: relative;
    .menu-actions{
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }

  .has-menu-actions{
    position: relative;
    .menu-actions{
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }

  .tag.is-notification{
    font-size: 7pt;
    font-weight: bold;
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }

  .navbar-link-notifications{
    .notification-icon{
      .tag.is-notification{
        position: absolute;
        right: -5px;
        top: 10px;
      }
    }
  }
  .is-community-icon-menu{
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 2px px 0px rgb(0 0 0 / 40%)
  }
  .is-community-icon{
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 40%);
    
    &.is-animated{
      transform: translatey(0px);
      animation: float 3s ease-in-out infinite;
    }
  }
  .item-progress-container{
    max-width: 300px;
  }

  .block-header {
    // display: flex;
    padding: 10px;
    border-radius: 16px;
    padding: 0;
    background: $white;
    font-family: $font;
    box-shadow: $dark-box-shadow;
    position: relative;

    .iframe-header iframe{
      border-radius: 16px 16px 0px 0px;
    }

    .bg-image-section {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      width: 100%;
      height: 300px;
      z-index: 2;
    }

    .block-icon{
      text-align: center;
      span{
        display: inline-block;
        background-color: $white;
        margin-top: -100px;
        border: $white solid 8px;
        border-radius: 8px;
        figure{
          img{
            border-radius: 8px;
          }
        }
      }
    }

    .block-avatar{
      text-align: center;
      span{
        display: inline-block;
        background-color: $white;
        margin-top: -90px;
        // border: $white solid 8px;
        border-radius: 50%;
        position: relative;
        figure{
          img{
            border-radius: 50%;
          }
        }
        .supporter-border-warning{
          border-radius: 50%;
          border: 4px solid $warning;
          padding: 4px;
          .supporter-icon{
            background-color: $warning;
          }
        }
        .supporter-border-success{
          border-radius: 50%;
          border: 4px solid $success;
          padding: 4px;
          .supporter-icon{
            background-color: $success;
          }
        }
        .supporter-border-light{
          border-radius: 50%;
          border: 4px solid $light;
          padding: 4px;
          .supporter-icon{
            background-color: $light;
          }
        }
        .supporter-border-info{
          border-radius: 50%;
          border: 4px solid $info;
          padding: 4px;
          .supporter-icon{
            background-color: $info;
            color: $white;
          }
        }
        .supporter-border-danger{
          border-radius: 50%;
          border: 4px solid $danger;
          padding: 4px;
          .supporter-icon{
            background-color: $danger;
            color: $white;
          }
        }
        .supporter-border-admin{
          border-radius: 50%;
          border: 4px solid $admin-color;
          padding: 4px;
          .supporter-icon{
            background-color: $admin-color;
            color: $white;
          }
        }
        .supporter-border-creator{
          border-radius: 50%;
          border: 4px solid $creator-color;
          padding: 4px;
          .supporter-icon.is-premium{
            border: $premium-color 2px solid;
            background-color: $premium-color-dark;
            color: $dark;
          }
          .supporter-icon.is-premium-vip{
            padding-top: 4px;
            border: $premium-color 2px solid;
            background-color: $premium-color-dark;
            color: $dark;
          }
          .supporter-icon.is-vip{
            padding-top: 4px;
            border: $vip-color 2px solid;
            background-color: $vip-color-dark;
            color: $dark;
          }
        }
        .supporter-border-premium{
          border-radius: 50%;
          border: 4px solid $premium-color;
          padding: 4px;
          .supporter-icon{
            border: $premium-color 2px solid;
            background-color: $premium-color-dark;
            color: $dark;
          }
        }
        .supporter-border-vip{
          border-radius: 50%;
          border: 4px solid $vip-color;
          padding: 4px;
          .supporter-icon{
            padding-top: 4px;
            border: $vip-color 2px solid;
            background-color: $vip-color-dark;
            color: $dark;
          }
        }
        .supporter-border-premium-vip{
          border-radius: 50%;
          border: 4px solid $premium-vip-color;
          padding: 4px;
          .supporter-icon{
            border: $premium-color 2px solid;
            background-color: $premium-color-dark;
            padding-top: 4px;
            color: $dark;
          }
        }
        .supporter-border-white{
          border-radius: 50%;
          border: 4px solid $white;
          padding: 4px;
        }
        .supporter-icon{
          position: absolute;
          width: 30px;
          height: 30px;
          right: 0;
          bottom: 0;
          // background-color: #ffffff85;
          background-color: white;
          border-radius: 50%;
          padding: 2px;
        }
      }
    }

    .block-heading {
      font-family: $font-alt;
      font-weight: 600;
      font-size: 1.1rem;
      margin-bottom: 4px;
      .block-description {
        padding: 20px;
        padding-top: 0;
        // padding-bottom: 20px;
      }
      
    }

  }
  
  // .box, .card {
  //   border-radius: 16px;
  // }
  .message-body.card, .message-body.box {
    border-radius: 5px;
  }
  .flex-table-cell.is-narrow{
    flex: none;
    width: unset;
  }
  .flex-table-cell:not(.is-media) .item-name {
    display: block;
    @media (max-width: $tablet) {
      text-align: right;
    }
  }
  .flex-table .flex-table-cell:before {
    @media (max-width: $tablet) {
      margin-right: 10px;
    }
  }
  .button-radio {
    width: 100%;
    height: auto;
    // padding-bottom: 30px;
    text-align: left;
    &.is-primary:has(> input:checked), &.is-primary:hover {
      background-color: $primary;
      color: $white !important;
      div {
        color: $white !important;
      }
    }
    &.is-primary:has(> input:checked) {
      font-weight: bold;
    }
    figure {
      text-wrap: wrap;
    }
  }
  .profile-dropdown .dropdown-menu .dropdown-content .dropdown-head {
    display: flex;
    align-items: center;
    padding: 20px 16px 20px 16px;
    margin-bottom: 12px;
    background: #fafafa;
  }

  .webapp-navbar {
    z-index: 39;
    .webapp-navbar-inner {
      .right, .left {
        width: auto;
      }
      .centered-links {
        .centered-link {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
    @media (max-width: $tablet){
      // display: block;
      bottom: 0;
      top: unset;
      height: 80px;
      border-top: $light solid 1px;
      .webapp-navbar-inner {
        padding: 0;
        .right, .left {
          display: none;
        }
        .centered-links {
          .centered-link {
            // padding: 20px 10px;
            .menu-label{
              // display: none;
            }
          }
        }
      }
    }
  }
  .form-post-container {
    .upload-image {
      .image{
        max-width: 100%;
        width: 100%;
        img{
          border-radius: 16px;
        }
      }
    }
  }
  .show-entry-target {
    .image-subscriber-only {
      margin: auto;
      img {
        margin: auto;
        max-width: 300px;
      }
    }
    .plyr .plyr__video-wrapper video{
      max-height: 500px;
    }
  }
  .entry-media-image {
    max-width: 100%;
    width: 100%;
    img{
      border-radius: 16px;
    }
  }
  .player {
    // --plyr-color-main: $primary;
  }
  .plyr--video{
    border-radius: 16px;
  }
  .trix-content{
    .iframe-container {
      iframe{
        border-radius: 16px;
      }
    }
  }
  .timeline {
    &.is-centered {
      @media (max-width: $desktop){
        .timeline-header{
          display: flex;
          width: 100%;
          align-self: center;
        }
        .timeline-item{
          display: -webkit-flex;
          position: relative;
          margin-left: 2em;
          padding-bottom: 2em;
          width: 92%;

          // width: 50%;
          // align-self: flex-end;
          // flex-direction: row;
          &:nth-of-type(2n){
            align-self: flex-end;
            flex-direction: row;
            margin-left: $timeline-item-padding-left;
            margin-right: 0;
            &::before{
              // right: -($timeline-line-width / 2);
              // left: auto;
              right: auto;
              left: -($timeline-line-width / 2);
            }
            .timeline-marker{
              transform: translateX(-50%);
            }
            .timeline-content{
              padding: $timeline-content-padding;
              text-align: left;
              display: block;
              flex-direction: unset;
              align-items: unset;
              flex-basis: unset;
            }
          }
          &:nth-of-type(2n+1){
            &::before{
              content: "";
              background-color: $timeline-line-color;
              display: block;
              width: $timeline-line-width;
              height: 100%;
              position: absolute;
              top: 0;
            }
          }   
        }
        // .timeline-item:nth-of-type(2n) {
        //   .timeline-content {
        //     padding: 1em 0 0 2em;
        //     text-align: left;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: flex-end;
        //     flex-basis: 100%;
        //   }
        // }
        
        // .timeline-item {
        //   display: -webkit-flex;
        //   position: relative;
        //   margin-left: 2em;
        //   padding-bottom: 2em;
        //   width: auto;
        //   &::before {
        //     content: "";
        //     background-color: #dbdbdb;
        //     display: block;
        //     width: 1px;
        //     height: 100%;
        //     position: absolute;
        //     left: -0.5px;
        //     top: 0;
        //   }
        //   &:nth-of-type(2n) {
        //     align-self: flex-end;
        //     flex-direction: row;
        //     margin-right: 0;
        //   }
        // }
      }
    }
    .timeline-item {
      
      .is-icon.is-youtube{
        background-color: $color-youtube;
        border-color: $color-youtube;
        color: $white;
      }
      .timeline-content {
        .heading {
          margin-bottom: 0px;
        }
      }
      &.is-large{ 
        .timeline-content {
          margin: 10px;
          width: 100%;
        }
        .timeline-marker.is-icon {
          width: 50px;
          height: 50px;
          i{
            font-size: $size-4 !important;
          }
        }
      }
    }
  }
  .mission-rating{
    div.stars{
      // width: 270px;
      display: inline-block;
      &.is-small{
        label.star {
          font-size: 12px;
        }
      }
    }
    input.star{
      display: none;
    }
    label.star {
      float: right;
      padding: 10px;
      font-size: 36px;
      color: #444;
      transition: all .2s;
    }
    input.star:checked ~ label.star:before {
      content: "\f005";
      color: $orange;
      transition: all .25s;
      font-weight: 800;
    }
    input.star-5:checked ~ label.star:before {
      color: $green;
      // text-shadow: 0 0 20px #952;
    }
    input.star-1:checked ~ label.star:before {
      color: #F62;
    }
    label.star:hover{
      transform: rotate(-15deg) scale(1.3);
    }
    label.star:before{
      content: "\f005";
      font-family: "Font Awesome 5 Free";
      font-weight: 400;
    } 
  }
  .mission-checkin{
    div.stars{
      // width: 270px;
      display: inline-block;
      &.is-small{
        label.star {
          font-size: 12px;
        }
      }
    }
    input.star{
      display: none;
    }
    label.star {
      float: right;
      padding: 10px;
      font-size: 36px;
      color: #444;
      transition: all .2s;
    }
    input.star:checked ~ label.star:before {
      content: "\f005";
      color: $orange;
      transition: all .25s;
      font-weight: 800;
    }
    input.star-5:checked ~ label.star:before {
      color: $green;
      // text-shadow: 0 0 20px #952;
    }
    input.star-1:checked ~ label.star:before {
      color: #F62;
    }
    label.star:hover{
      transform: rotate(-15deg) scale(1.3);
    }
    label.star:before{
      content: "\f005";
      font-family: "Font Awesome 5 Free";
      font-weight: 400;
    } 
  }
  .mission-yes-no{
    .yes-no-radio.is-primary{
      padding-top: 2rem;
      padding-bottom: 2rem;
      &:has(> input:checked) {
        background-color: #7044FF;
        color: #fff;
      }
      input{
        display: none;
      }  
    }
    
  }
  .modal-confetti-canvas{    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.uppy-Dashboard-innerWrap .uppy-Dashboard-poweredBy{
  display: none;
}

#uppy-DashboardContent-panel--editor .uppy-DashboardContent-back{
  display: none;
}
#uppy-DashboardContent-panel--Webcam .uppy-DashboardContent-back{
  display: none;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 5px 0px rgba(0,0,0,0.4);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 10px 10px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 5px 0px rgba(0,0,0,0.4);
		transform: translatey(0px);
	}
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}