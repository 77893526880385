@import "bulma/bulma";

@import "stylesheets/abstracts/shadows";
@import "stylesheets/abstracts/typography";

@import "stylesheets/abstracts/animations";
@import "stylesheets/icons.min.css";
// @import "stylesheets/abstracts/helpers";
@import 'bulma-helpers/css/bulma-helpers.min';
// @import "font-awesome-sprockets";  
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';

@import "stylesheets/elements/buttons";
@import "stylesheets/elements/blocks";
@import "stylesheets/elements/cards";
@import "stylesheets/elements/content";
@import "stylesheets/elements/forms-general";
@import "stylesheets/elements/forms-combos";
@import "stylesheets/elements/forms-checkboxes";
@import "stylesheets/elements/forms-slider";
@import "stylesheets/elements/icons";
@import "stylesheets/elements/links";
@import "stylesheets/elements/lists";
@import "stylesheets/elements/tags";
@import "stylesheets/elements/dashboard";
@import "stylesheets/components/tabs";
@import "stylesheets/components/table";
@import "stylesheets/components/avatar";
@import "stylesheets/components/dropdowns";
@import "stylesheets/components/placeload";
@import "stylesheets/components/progress";
@import "stylesheets/components/accordion";
@import "stylesheets/components/timeline";
@import "stylesheets/components/stat_widgets";
// @import "stylesheets/components/modals";
@import "stylesheets/layout/utilities";
@import "stylesheets/layout/right-panel";
@import "stylesheets/layout/navbar";
@import "stylesheets/layout/responsive";
@import "stylesheets/layout/sidebar-mobile";
@import "stylesheets/layout/wizard";
@import "stylesheets/layout/forms";

// web components
@import "stylesheets/components/notification_alert";
@import "stylesheets/components/home_header";
@import "stylesheets/components/home_ranking";
@import "stylesheets/components/story_modal";

// @import "bulma-extensions/src/sass/index";
@import "bulma-steps-component/bulma-steps";
// @import "bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip";
@import "@creativebulma/bulma-tooltip/dist/bulma-tooltip";
@import "bulma-extensions/bulma-checkradio/src/sass/index";
@import "bulma-extensions/bulma-pricingtable/src/sass/index";
@import "bulma-extensions/bulma-pageloader/src/sass/index";
@import "bulma-extensions/bulma-switch/src/sass/index";
@import "bulma-extensions/bulma-divider/src/sass/index";
@import "bulma-extensions/bulma-timeline/src/sass/index";
// @import "bulma-extensions/bulma-calendar/dist/css/bulma-calendar.min.css";
// @import "bulma-calendar/dist/css/bulma-calendar.min";
@import "bulma-carousel/dist/css/bulma-carousel.min";
@import "swiper/css/bundle";
@import 'izitoast/dist/css/iziToast.min';

@import 'aos/dist/aos';
@import 'stylesheets/cookiealert';
@import 'stylesheets/custom-izitoast';
@import "stylesheets/actiontext.scss";
@import "stylesheets/site.scss";
@import "stylesheets/community.scss";
@import "stylesheets/patients.scss";
@import '@uppy/core/dist/style.css';
@import '@uppy/dashboard/dist/style.css';
@import '@uppy/image-editor/dist/style.css';
@import '@uppy/webcam/dist/style.css';
@import 'flatpickr/dist/flatpickr.min.css';
@import 'intl-tel-input/build/css/intlTelInput.css';
@import 'plyr/dist/plyr.css';

@import 'animate.css';

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  // .iti__flag {background-image: url("path/to/flags@2x.png");}
}
.iti { width: 100%;}

@media(max-width: $desktop) {
  .columns.is-reversed-touch {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media(max-width: $tablet) {
  .columns.is-reversed-mobile {
    flex-direction: column-reverse;
    display: flex;
  }
}
body{
  font-family: $font;
}
.container{
  background: transparent;
}
.pageloader{
  background: #5b5963a8;
  z-index: 9998;
}
.tag:not(body){
  line-height: 2.4;
}
.tag.is-small{
  line-height: 2;
  height: auto;
  font-size: 0.6rem;
}
.image.is-256x256{
  width: 256px;
  height: 256px;
}
.image.is-256{
  width: 256px;
}
.m-auto{
  margin: auto;
}
.mt-auto{
  margin-top: auto;
}
.mb-auto{
  margin-bottom: auto;
}
.h-100{
  min-height: 100%;
}
.w-100{
  width: 100%;
}
.reverse-row-order {
  flex-direction: row-reverse;
}
.footer{
  border-top: $grey-lighter solid 1px;
  background: $white;
  margin-top: 200px;
  .footer-logo{
    img{
      max-width: 150px;
    }
  }
  .payment-method-image {
    img {
      max-width: 100px;
    }
  }
}
.image{
  svg{
    max-width: 100%;
    width: auto !important;
    height: auto !important;
    [fill="#ffbd21"], [fill="#FFBD21"], [fill="#F6BF4A"], [fill="#f6bf4a"], [fill="#ffbd20"], [fill="#FFBD20"]{
      fill: $image-background-color;
    }
  }
}

.is-checkradio.has-background-color[type="checkbox"]:not(:checked) + label::before, .is-checkradio.has-background-color[type="checkbox"]:not(:checked) + label:before {
  background-color: transparent !important;
  border-color: #dbdbdb !important;
}
// .is-copy-link{
//   color: $purple;
//   text-decoration: underline;
//   border-left-color: transparent;
// }

.is-copy-link-btn{
  color: $purple;
  text-decoration: underline;
  border-left-color: transparent;
}
.turbolinks-progress-bar, .turbo-progress-bar {
  height: 5px;
  background-color: $primary;
}
.is-twitter{
  color: $color-twitter;
  border-color: $color-twitter;
}
.is-facebook{
  color: $color-facebook;
  border-color: $color-facebook;
}
.is-whatsapp{
  color: $color-whatsapp;
  border-color: $color-whatsapp;
}
.is-twitch{
  color: $color-twitch;
  border-color: $color-twitch;
}
.button.is-twitch{
  background-color: $color-twitch;
  border-color: $color-twitch;
  color: $white;
}
.button.is-youtube{
  background-color: $color-youtube;
  border-color: $color-youtube;
  color: $white;
}
.button.is-twitter{
  background-color: $color-twitter;
  border-color: $color-twitter;
  color: $white;
}
.button.is-whatsapp{
  background-color: $color-whatsapp;
  border-color: $color-whatsapp;
  color: $white;
}
.button.is-facebook{
  background-color: $color-facebook;
  border-color: $color-facebook;
  color: $white;
}
.button.is-discord{
  background-color: $color-discord;
  border-color: $color-discord;
  color: $white;
}
.button.is-mailchimp{
  background-color: $color-mailchimp;
  border-color: $color-mailchimp;
  color: $dark;
}
.is-instagram{
  color: $color-instagram;
  border-color: $color-instagram;
}
.is-discord{
  color: $color-instagram;
  border-color: $color-instagram;
}
.is-orange{
  color: $orange;
  border-color: $orange;
}
.has-text-orange{
  color: $orange;
}
.notification:empty{
  display: none;
}
.has-text-purple{
  color: $purple;
}
.is-acqua{
  color: $acqua;
  border-color: $acqua;
  &progress{
    color: $acqua-darker;
    background-color: $acqua;
  }
  &.tag{
    background-color: $acqua-lighter;
    color: $acqua-darker;
  }
  &.notification{
    background-color: $acqua-lighter;
    color: $acqua-darker;
  }
}
.is-subscription-tag {
  color: $yellow;
  border-color: $yellow;
  &progress{
    color: $dark;
    background-color: $yellow;
  }
  &.tag{
    background-color: $yellow-lighter;
    color: $dark;
  }
  &.notification{
    background-color: $yellow-lighter;
    color: $dark;
  }
}
.is-premium-tag {
  color: $yellow;
  border-color: $yellow;
  &progress{
    color: $dark;
    background-color: $yellow;
  }
  &.is-lighter{
    background-color: $yellow-lighter;
    color: $dark;
  }
  &.notification{
    background-color: $yellow-lighter;
    color: $dark;
  }
}

.is-vip-tag {
  color: $acqua;
  border-color: $acqua;
  &progress{
    color: $acqua-darker;
    background-color: $acqua;
  }
  &.is-lighter{
    background-color: $acqua-lighter;
    color: $acqua-darker;
  }
  &.notification{
    background-color: $acqua-lighter;
    color: $acqua-darker;
  }
}

.has-background-acqua {
  background-color: $acqua;
}
.has-background-orange {
  background-color: $orange;
}
.has-text-orange {
  color: $orange !important;
}
.is-width-100 {
  width: 100%;;
}

a.is-inverted:not(.button) {
  color: $dark;
  &:hover {
    color: $primary;
  }
}

.required::after {
  content: " *";
  color: $danger;
}

.avatar-upload-container{
  
  position: relative;
  .image{
    max-width: 280px;
    text-align: center;
    margin: auto;
    img{
      border: 1px solid $grey-light;
    }
  }
  
  .avatar-file-trigger{
    width: 128px;
    height: 128px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.1;
    &:hover{
      opacity: 1;
    }
  }
}

.logo-upload-container{
  
  position: relative;
  .image{
    max-width: 280px;
    text-align: center;
    margin: auto;
    img{
      border: 1px solid $grey-light;
    }
  }
  
  .logo-file-trigger{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.1;
    &:hover{
      opacity: 1;
    }
  }
}

.icon-upload-container{
  
  position: relative;
  .image{
    max-width: 200px;
    text-align: center;
    // margin: auto;
    margin: 0;
    img{
      border: 1px solid $grey-light;
      height: 100% !important;
    }
  }
  
  .icon-file-trigger{
    width: 128px;
    height: 128px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    // right: 0;
    // margin: auto;
    opacity: 0.1;
    &:hover{
      opacity: 1;
    }
  }
}
.logo-upload-container{
  
  position: relative;
  .image{
    max-width: 200px;
    text-align: center;
    // margin: auto;
    margin: 0;
    img{
      border: 1px solid $grey-light;
      height: 100% !important;
    }
  }
  
  .icon-file-trigger{
    width: 200px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    // right: 0;
    // margin: auto;
    opacity: 0.1;
    &:hover{
      opacity: 1;
    }
  }
}
img.border-gradient {
  background: -webkit-linear-gradient(left top, $purple 0%, $yellow 100%);
  padding: 7px;
}
img.is-icon{
  border-radius: 7%;
}
.ml-auto{
  margin-left: auto;
}
.mr-auto{
  margin-right: auto;
}
.button.is-static{
  background-color: $white;
}
.has-text-whatsapp{
  color: $color-whatsapp;
}
.has-text-facebook{
  color: $color-facebook;
}
.has-text-twitter{
  color: $color-twitter;
}
.has-text-discord{
  color: $color-discord;
}
.has-text-youtube{
  color: $color-youtube;
}
.has-text-twitch{
  color: $color-twitch;
}
.has-text-instagram{
  color: $color-instagram;
}
.has-text-linkedin{
  color: $color-linkedin;
}
.control.has-icons-right .icon.has-tooltip{
  pointer-events: auto !important;
  z-index: 2;
}
label.label{
  font-size: 0.8rem;
  font-weight: bold;
}
.tabs-content li {
  display: none;
  list-style: none;
}

.tabs-content li.is-active {
  display: block;
}
.is-overflow-auto{
  overflow: auto;
}

.autoComplete_list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin-top: 0.5rem;
  border-radius: 0.6rem;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
  border: 1px solid rgba(33, 33, 33, 0.07);
  z-index: 1000;
  outline: none;
}

.autoComplete_result {
  margin: 0.3rem;
  padding: 0.3rem 0.5rem;
  list-style: none;
  text-align: left;
  font-size: 1rem;
  color: $dark;
  transition: all 0.1s ease-in-out;
  border-radius: 0.35rem;
  background-color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
}

.autoComplete_result::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_result:hover {
  cursor: pointer;
  background-color: $purple-light;
}

.autoComplete_highlighted {
  color: $danger;
  font-weight: bold;
}

.autoComplete_highlighted::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_selected {
  cursor: pointer;
  background-color: rgba(255, 122, 122, 0.15);
}
hr{
  margin: 1rem 0;
}
@media(max-width: $desktop) {
  .columns.is-reversed-touch {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media(max-width: $tablet) {
  .columns.is-reversed-mobile {
    flex-direction: column-reverse;
    display: flex;
  }
}
.section {
  @media(max-width: $tablet) {
    padding: 3rem 0.5rem;
  }
}
.pagination{
  .pagination-link {
    border-radius: 50%;
    color: $purple;
    border: none;

    &.is-current {
      background-color: $purple-lighter;
      color: $purple;
      // border-color: $purple;
    }
    &:hover{
      background-color: $purple-light;
    }
  }
  .pagination-next, .pagination-previous{
    color: $purple;
    border: none;
    &:hover{
      background-color: $purple-light;
    }
    &[disabled]{
      background: none;
    }
  }
}

.fadein, .fadeout {
  opacity: 0;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.fadein {
  opacity: 1;
}

a {
  -moz-transition: text-decoration 0.4s ease-in-out;
  -o-transition: text-decoration 0.4s ease-in-out;
  -webkit-transition: text-decoration 0.4s ease-in-out;
  transition: text-decoration 0.4s ease-in-out;
}