/*! _forms.scss | Huro | Css ninja 2020-2021 */

/* ==========================================================================
Form Layouts
========================================================================== */

/*
    1. Form Layout
    2. Stepper Form
*/

/* ==========================================================================
1. Form Layout
========================================================================== */

.form-layout {
  max-width: 740px;
  margin: 0 auto;

  &.is-webapp {
    margin-top: 30px;
  }

  &.is-split {
    max-width: 840px;

    .form-outer {
      .form-body {
        padding: 0;

        .form-section {
          display: flex;
          padding: 20px;

          &.is-grey {
            background: #fafafa;
          }

          .left,
          .right {
            padding: 20px 40px;
            width: 50%;

            h3 {
              font-family: $font-alt;
              font-weight: 600;
              font-size: 0.95rem;
              color: $dark-text;
              margin-bottom: 20px;
            }
          }

          .left {
            position: relative;
            border-right: 1px solid darken($fade-grey, 3%);

            .operator {
              position: absolute;
              top: 17px;
              right: -10px;
              text-transform: uppercase;
              font-family: $font;
              font-weight: 500;
              color: $dark-text;
              background: $white;
              padding: 4px 0;
            }
          }

          .radio-pills {
            display: flex;
            justify-content: space-between;

            .radio-pill {
              position: relative;

              input {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                cursor: pointer;

                &:checked {
                  + .radio-pill-inner {
                    background: $primary;
                    border-color: $primary;
                    box-shadow: $primary-box-shadow;
                    color: $white;
                  }
                }
              }

              .radio-pill-inner {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 40px;
                background: $white;
                border: 1px solid darken($fade-grey, 3%);
                border-radius: 8px;
                font-family: $font;
                font-weight: 600;
                font-size: 0.9rem;
                transition: all 0.3s;
              }
            }
          }
        }
      }
    }
  }

  &.is-separate {
    max-width: 1040px;

    .form-outer {
      background: none;
      border: none;

      .form-body {
        display: flex;

        .form-section {
          flex-grow: 2;
          padding: 10px;
          width: 50%;

          .form-section-inner {
            @extend .s-card;

            padding: 40px;

            &.has-padding-bottom {
              padding-bottom: 60px;
              height: 100%;
            }

            > h3 {
              font-family: $font-alt;
              font-size: 1.2rem;
              font-weight: 600;
              color: $dark-text;
              margin-bottom: 30px;
            }

            .columns {
              .column {
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
              }
            }

            .radio-boxes {
              display: flex;
              justify-content: space-between;
              margin-left: -8px;
              margin-right: -8px;

              .radio-box {
                position: relative;
                width: calc(50% - 16px);
                margin: 8px;

                input {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  opacity: 0;
                  cursor: pointer;

                  &:checked {
                    + .radio-box-inner {
                      background: $primary;
                      border-color: $primary;
                      box-shadow: $primary-box-shadow;

                      .fee,
                      p {
                        color: $smoke-white;
                      }
                    }
                  }
                }

                .radio-box-inner {
                  background: $white;
                  border: 1px solid darken($fade-grey, 3%);
                  text-align: center;
                  border-radius: 4px;
                  font-family: $font;
                  font-weight: 600;
                  font-size: 0.9rem;
                  transition: all 0.3s;
                  padding: 30px 20px;

                  .fee {
                    font-family: $font;
                    font-weight: 700;
                    color: $dark-text;
                    font-size: 2.4rem;
                    line-height: 1;

                    span {
                      &:after {
                        content: "$";
                        position: relative;
                        top: -10px;
                        font-size: 1.5rem;
                      }
                    }
                  }

                  p {
                    font-family: $font-alt;
                  }
                }
              }
            }

            .control {
              > p {
                padding-top: 12px;

                > span {
                  display: block;
                  font-size: 0.9rem;

                  span {
                    font-weight: 500;
                    color: $dark-text;
                  }
                }
              }
            }
          }

          .form-section-outer {
            .checkboxes {
              padding: 16px 0;

              .checkbox {
                padding: 0;
                font-size: 0.9rem;
              }
            }

            .button-wrap {
              .button {
                min-height: 60px;
                font-size: 1.05rem;
                font-weight: 600;
                font-family: $font-alt;
              }
            }
          }
        }
      }
    }
  }

  &.is-stacked {
    .form-outer {
      .form-body {
        padding: 0;

        .form-section {
          padding: 40px;
          border-bottom: 1px solid darken($fade-grey, 4%);

          &.is-grey {
            background: #fafafa;
          }

          .form-section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid darken($fade-grey, 4%);
            padding-bottom: 20px;
            margin-bottom: 30px;

            .left {
              h3 {
                font-family: $font-alt;
                font-weight: 600;
                color: $dark-text;
              }
            }
          }

          .form-section-inner {
            &.is-horizontal {
              max-width: 540px;
            }

            .field {
              &.is-horizontal {
                .field-label {
                  padding-top: 0.75em;
                }
              }
            }
          }

          .columns {
            .column {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
            }
          }

          .field {
            .control {
              .checkbox {
                padding: 0;
                padding-right: 10px;
                font-size: 0.9rem;
              }
            }
          }

          .participants {
            display: flex;
            padding-bottom: 10px;

            .h-avatar {
              margin-right: 8px;
            }

            .add-participant {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              width: 40px;
              min-width: 40px;
              border-radius: 50%;
              border: 1.6px dashed $light-text;
              color: $light-text;
              outline: none;
              padding: 0;
              background: none;
              margin-left: 4px;
              cursor: pointer;
              transition: all 0.3s;

              &:hover {
                border: 1.6px solid $primary;
                color: $primary;
              }

              svg {
                height: 16px;
                width: 16px;
              }
            }
          }

          .color-codes {
            display: flex;
            align-items: center;
            height: 38px;

            .color-code {
              height: 14px;
              width: 14px;
              border-radius: 50%;
              background: $white;
              margin-right: 10px;
              border: 3px solid $light-text;
              cursor: pointer;
              opacity: 0.6;
              transition: all 0.3s;

              &:hover {
                opacity: 1;
              }

              &.is-primary {
                border-color: $primary;
              }

              &.is-secondary {
                border-color: $secondary;
              }

              &.is-info {
                border-color: $info;
              }

              &.is-success {
                border-color: $success;
              }

              &.is-purple {
                border-color: $h-purple;
              }
            }
          }

          .add-link {
            display: inline-block;
            padding: 4px 0;
            font-family: $font;
            font-weight: 500;
            font-size: 0.9rem;
            color: $primary;
          }
        }
      }
    }
  }

  .form-outer {
    @extend .s-card;

    padding: 0;

    .form-header {
      padding: 12px 20px;
      border-bottom: 1px solid darken($fade-grey, 3%);
      transition: all 0.3s;

      &.is-stuck {
        background: $white;
        padding-right: 80px;
        border-left: 1px solid darken($fade-grey, 3%);
      }

      .form-header-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .left {
        h3 {
          font-family: $font-alt;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.3;
        }

        p {
          font-size: 0.95rem;
        }
      }
    }

    .form-body {
      padding: 20px 40px 40px 40px;
    }
  }
}

body {
  &.is-dark {
    .form-layout {
      &.is-split {
        .form-outer {
          .form-body {
            .form-section {
              &.is-grey {
                background: lighten($dark-sidebar, 4%) !important;
              }

              h3 {
                color: $dark-dark-text;
              }

              .left {
                border-color: lighten($dark-sidebar, 12%) !important;

                .operator {
                  background: lighten($dark-sidebar, 6%) !important;
                  color: $dark-dark-text;
                }

                .radio-pills {
                  .radio-pill {
                    input {
                      &:checked + .radio-pill-inner {
                        border-color: $accent;
                        background: $accent;
                        box-shadow: $accent-box-shadow;
                        color: $smoke-white;
                      }
                    }

                    .radio-pill-inner {
                      background: lighten($dark-sidebar, 2%);
                      border-color: lighten($dark-sidebar, 12%);
                      color: $dark-dark-text;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.is-separate {
        .form-outer {
          background: none !important;

          .form-body {
            .form-section {
              .form-section-inner {
                > h3 {
                  color: $dark-dark-text;
                }

                .radio-boxes {
                  .radio-box {
                    input:checked + .radio-box-inner {
                      background: $accent;
                      border-color: $accent;
                      box-shadow: $accent-box-shadow;

                      .fee,
                      p {
                        color: $smoke-white;
                      }
                    }

                    .radio-box-inner {
                      background: lighten($dark-sidebar, 2%);
                      border-color: lighten($dark-sidebar, 12%);

                      .fee {
                        color: $dark-dark-text;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.is-stacked {
        .form-outer {
          .form-body {
            .form-section {
              border-color: lighten($dark-sidebar, 12%);

              &.is-grey {
                background: lighten($dark-sidebar, 4%);
              }

              .form-section-header {
                border-color: lighten($dark-sidebar, 12%);

                .left {
                  h3 {
                    color: $dark-dark-text;
                  }
                }
              }

              .form-section-inner {
                .add-link {
                  color: $accent;
                }

                .color-codes {
                  .color-code {
                    background: lighten($dark-sidebar, 6%);

                    &.is-primary {
                      border-color: $accent;
                    }
                  }
                }

                .participants {
                  .add-participant {
                    &:hover {
                      border: 1.6px solid $accent;
                      color: $accent;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .form-outer {
        .form-header {
          border-color: lighten($dark-sidebar, 12%);

          &.is-stuck {
            background: $dark-sidebar;
            border-color: lighten($dark-sidebar, 6%);
          }

          .left {
            h3 {
              color: $dark-dark-text;
            }
          }
        }

        .form-body {
          .field {
            .control {
              .input,
              .textarea {
                &:focus {
                  border-color: $accent;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .form-layout {
    &.is-split {
      .form-outer {
        .form-body {
          .form-section {
            flex-direction: column;
            padding-right: 0;
            padding-left: 0;

            .left,
            .right {
              width: 100%;
              padding-right: 30px;
              padding-left: 30px;
            }

            .left {
              border-right: none;
              border-bottom: 1px solid darken($fade-grey, 3%);
              padding-bottom: 40px;

              .operator {
                top: unset;
                bottom: -14px;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                max-width: 60px;
              }
            }

            .right {
              padding-top: 30px;
            }
          }
        }
      }
    }

    &.is-separate {
      .form-outer {
        .form-body {
          padding-left: 0;
          padding-right: 0;
          flex-direction: column;

          .form-section {
            width: 100%;

            .form-section-inner {
              padding: 30px;
            }
          }
        }
      }
    }

    &.is-stacked {
      .form-outer {
        .form-body {
          .is-vhidden {
            display: none !important;
          }
        }
      }
    }

    .form-outer {
      .form-header {
        .form-header-inner {
          flex-direction: column;

          .left {
            text-align: center;
            margin-bottom: 12px;
          }

          .right {
            width: 100%;

            .buttons {
              display: flex;
              justify-content: space-between;
              margin: 0;

              .button {
                margin: 0;
                width: 49%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .form-layout {
    &.is-split {
      .form-outer {
        .form-body {
          .form-section {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
    }

    &.is-separate {
      .form-outer {
        .form-body {
          padding-left: 0;
          padding-right: 0;

          //flex-direction: column;

          .form-section {
            //width: 100%;

            .form-section-inner {
              padding: 30px;
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
2. Stepper Form
========================================================================== */

.is-webapp {
  .stepper-form {
    max-width: 880px;
    margin: 0 auto;
  }
}

.stepper-form {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 3rem;
  margin-bottom: 5rem;

  .form-sections {
    flex-grow: 2;
    max-width: 640px;
    padding-right: 4rem;

    .form-section {
      display: none;

      &.is-active {
        display: block;
      }

      + .form-section {
        margin-top: 4rem;
      }

      .form-section-title {
        font-family: $font-alt;
        font-weight: 600;
        color: $dark-text;
        margin-bottom: 1rem;

        button {
          position: relative;
          top: 4px;
          padding: 0;
          height: 18px;
          width: 18px;
          border: none;
          background: none;
          cursor: pointer;
          margin-left: 0.25rem;
          outline: none;

          svg {
            height: 18px;
            width: 18px;
            pointer-events: none;
          }
        }
      }

      .fieldset {
        padding: 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid $border;
        background: darken($widget-grey, 3%);

        .fieldset-separator {
          margin: 1.5rem 0;
          border-top: 1px solid $border;
        }
      }

      .field {
        > label {
          margin-bottom: 0.25rem;
          display: inline-block;
        }

        > .buttons {
          padding: 2rem 0;
        }
      }

      .flex-label {
        display: flex;
        align-items: center;
        height: 100%;

        h4 {
          font-family: $font;
          font-weight: 500;
          color: $dark-text;
        }
      }

      .subcontrol {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 175px;
        padding-right: 1rem;

        .checkbox {
          padding: 0;
        }
      }

      .switch-segment {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > span {
          font-family: $font;
          font-size: 0.9rem;

          &:first-child {
            color: $dark-text;
          }

          &:nth-child(3) {
            color: $light-text;
          }
        }

        .form-switch {
          transform: scale(0.7);
          margin: 0 0.25rem;
        }
      }

      .input-button {
        height: 44px;
        width: 100%;
        border: 2px dashed $border;
        border-radius: 0.65rem;
        background: darken($widget-grey, 3%);
        display: flex;
        align-items: center;
        padding-left: calc(0.75em - 1px);
        padding-right: calc(0.75em - 1px);
        padding-top: 0;
        padding-bottom: 0;
        color: $dark-text;
        cursor: pointer;
        transition: color 0.3s, background-color 0.3s, border 0.3s,
          box-shadow 0.3s;

        &:hover {
          background: $white;
          border: 2px solid $primary;
          color: $primary;
          box-shadow: $light-box-shadow;
        }

        svg {
          height: 18px;
          width: 16px;
        }

        span {
          font-family: $font;
          margin-left: 0.75rem;
        }
      }

      .options {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        .option {
          position: relative;
          width: calc(33.3% - 1rem);
          margin: 0.5rem;

          input {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;

            &:checked {
              ~ .indicator {
                transform: scale(1);
              }

              ~ .option-inner {
                border-color: $primary;
                box-shadow: $light-box-shadow;

                i {
                  color: $primary;
                }
              }
            }
          }

          .indicator {
            position: absolute;
            top: 1rem;
            right: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 20px;
            color: $white;
            background: $primary;
            border-radius: 50%;
            transform: scale(0);
            transition: transform 0.3s;

            svg {
              height: 14px;
              width: 14px;
              stroke-width: 3px;
            }
          }

          .option-inner {
            padding: 1.5rem;
            background: $white;
            border: 1px solid $border;
            border-radius: 0.5rem;
            transition: border 0.3s, box-shadow 0.3s;

            h4 {
              color: $dark-text;
              font-weight: 600;
              font-family: $font-alt;
            }

            p {
              font-size: 0.9rem;
            }

            i {
              font-size: 2.25rem;
              color: $light-text;
              margin-bottom: 0.25rem;
            }
          }
        }
      }

      .validation-box {
        display: flex;
        padding: 2rem;
        background: $white;
        border: 1px solid $border;
        border-radius: 0.5rem;
        transition: border 0.3s, box-shadow 0.3s;

        .box-content {
          h3 {
            font-family: $font-alt;
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 0.75rem;
          }

          p {
            font-size: 1rem;
          }
        }

        .box-illustration {
          position: relative;
          height: 100px;
          min-width: 40%;

          img {
            position: absolute;
            right: 0;
            bottom: 0;
            max-height: 180px;
          }
        }
      }

      .form-section-output {
        margin-top: 1.5rem;

        .output {
          height: 52px;
          width: 100%;
          border: 1px solid $border;
          border-radius: 0.65rem;
          background: $white;
          display: flex;
          align-items: center;
          padding-left: calc(1em - 1px);
          padding-right: calc(1em - 1px);
          padding-top: 0;
          padding-bottom: 0;
          color: $dark-text;
          cursor: pointer;
          transition: color 0.3s, background-color 0.3s, border 0.3s,
            box-shadow 0.3s;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          > svg {
            height: 18px;
            width: 18px;
            margin-right: 0.75rem;
            stroke: $light-text;
          }

          > span {
            font-weight: 500;
            font-family: $font;
            color: $dark-text;
          }

          .action {
            margin-left: auto;

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 38px;
              width: 38px;
              min-width: 38px;
              background: none;
              border: none;
              padding: 0;
              cursor: pointer;
              border-radius: 0.5rem;
              transition: background-color 0.3s;

              &:hover {
                background: darken($widget-grey, 1%);
              }

              svg {
                height: 18px;
                width: 18px;
                stroke-width: 1.5px;
              }
            }
          }
        }
      }
    }

    .navigation-buttons {
      padding: 2rem 0;

      .button {
        min-height: 52px;
        min-width: 160px;
      }
    }
  }

  .form-stepper {
    position: sticky;
    top: -30px;
    padding-top: 50px;

    .form-help {
      position: relative;
      max-width: 300px;
      padding: 1.75rem;
      border: 1px solid $border;
      border-radius: 0.65rem;
      background: $white;

      .form-help-inner {
        display: none;

        &.is-active {
          display: block;
        }
      }

      h3 {
        font-family: $font-alt;
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0;
        height: 18px;
        width: 18px;
        border: none;
        background: none;
        cursor: pointer;
        margin-left: 0.25rem;
        outline: none;

        svg {
          height: 18px;
          width: 18px;
          pointer-events: none;
        }
      }

      .list-wrap {
        padding: 0.75rem 0;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 0.15rem;

          span {
            font-family: $font;
            color: $light-text;
            font-weight: 300;
          }

          svg {
            height: 14px;
            width: 14px;
            stroke-width: 3px;
            margin-right: 0.45rem;
            stroke: $primary;
          }
        }
      }
    }

    .step-number {
      font-family: $font;
      font-weight: 500;
      font-size: 0.75rem !important;
    }

    .steps-segment {
      &.is-active {
        .step-info {
          color: $dark-text;
          font-weight: 600;
        }
      }
    }
  }
}

.mobile-steps {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 60px;
  left: 0;
  height: 65px;
  width: 100%;
  background: $white;
  border-bottom: 1px solid $border;
  z-index: 25;
  transform: translateY(-100%);
  transition: transform 0.3s;

  &.is-active {
    transform: translateY(0);
  }

  .steps {
    position: relative;
    top: 10px;
  }

  .steps-segment {
    &.is-active {
      .steps-content {
        opacity: 1 !important;
      }
    }

    .steps-content {
      opacity: 0 !important;
    }
  }
}

body {
  &.is-dark {
    .stepper-form {
      .form-sections {
        .form-section {
          .form-section-title {
            span {
              color: $dark-dark-text;
            }

            svg {
              stroke: $dark-dark-text;
            }
          }

          .input-button {
            background-color: lighten($dark-sidebar, 2%) !important;
            border-color: lighten($dark-sidebar, 12%) !important;
            color: $dark-dark-text;

            &:hover {
              color: $accent;
              border: 2px solid $accent !important;
            }
          }

          .fieldset {
            background: darken($dark-sidebar, 2%);
            border-color: lighten($dark-sidebar, 12%);
          }

          .fieldset-separator {
            border-color: lighten($dark-sidebar, 12%);
          }

          .flex-label h4 {
            color: $dark-dark-text;
          }

          .switch-segment {
            span {
              &:first-child {
                color: $dark-dark-text;
              }
            }
          }

          .options {
            .option {
              .indicator {
                background: $accent;
              }

              input {
                &:checked {
                  ~ .indicator {
                    transform: scale(1);
                  }

                  ~ .option-inner {
                    border-color: $accent !important;

                    i {
                      color: $accent;
                    }
                  }
                }
              }

              .option-inner {
                background-color: lighten($dark-sidebar, 2%) !important;
                border-color: lighten($dark-sidebar, 12%) !important;

                h4 {
                  color: $dark-dark-text;
                }
              }
            }
          }

          .validation-box {
            background-color: lighten($dark-sidebar, 2%) !important;
            border-color: lighten($dark-sidebar, 12%) !important;

            .box-content h3 {
              color: $dark-dark-text;
            }
          }

          .form-section-output {
            .output {
              background-color: lighten($dark-sidebar, 2%) !important;
              border-color: lighten($dark-sidebar, 12%) !important;
              color: $dark-dark-text;

              > span {
                color: $dark-dark-text;
              }

              .action {
                button {
                  &:hover {
                    background: lighten($dark-sidebar, 5%);
                  }

                  svg {
                    stroke: $light-text;
                  }
                }
              }
            }
          }
        }
      }

      .form-stepper {
        .steps {
          &:not(.is-hollow) {
            .steps-marker:not(.is-hollow) {
              background-color: $accent;
            }
          }

          .steps-segment {
            &.is-active {
              ~ .steps-segment::after {
                background: darken($dark-sidebar, 5%);
              }

              ~ .steps-segment {
                .steps-marker:not(.is-hollow) {
                  background: darken($dark-sidebar, 5%);
                }
              }

              &::after {
                background: darken($dark-sidebar, 5%);
              }

              .steps-content {
                .step-info {
                  color: $dark-dark-text;
                }
              }
            }

            &::after {
              background-color: $accent;
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
3. Mobile Steps
========================================================================== */

@media (max-width: 767px) {
  .mobile-steps {
    display: flex;
  }

  .stepper-form {
    padding-top: 0;

    .form-sections {
      padding-right: 0;
      overflow: hidden;

      .form-section {
        .form-section-title {
          button {
            display: none;
          }
        }

        .subcontrol {
          min-width: 138px;
        }

        .options {
          .option {
            width: calc(50% - 1rem);
          }
        }

        .validation-box {
          .box-illustration {
            min-width: 30%;

            img {
              right: -34px;
              bottom: -28px;
            }
          }
        }
      }
    }

    .form-stepper {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .stepper-form {
    padding-top: 0;

    .form-sections {
      max-width: 500px;
    }

    .form-stepper {
      top: 40px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .stepper-form {
    padding-top: 0;
    .form-stepper {
      .form-help {
        right: 2rem;
      }
    }
  }
}
