// Place all the styles related to the site controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/
// @import "custom-variables";
@import 'bulma/sass/utilities/initial-variables.sass';
@import url('//fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap');
// import 'font-awesome/css/font-awesome.css'
// @import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Gochi+Hand&display=swap');

body.onboard{
  background-color: $grey-lightest;
  .steps.is-hollow .steps-marker, .steps-marker.is-hollow {
    background-color: white;
    border: 0.1em solid #7044FF;
  }
  .steps.is-small.is-horizontal .steps-segment:not(:last-child):after {
    height: 0.1em;
    top: calc(0.75rem - (0.05em));
  }
  @media(min-width: 769px) {
    .steps.is-small:not(.is-vertical) .steps-segment:not(:last-child):after {
      height: 0.1em;
      top: calc(0.75rem - (0.05em));
    }
    .steps.is-small:not(.is-vertical).has-content-centered .steps-marker {
      position: absolute;
      left: calc(50% - 0.75rem);
      padding-top: 0.15rem;
    }
  }
  .community-signup-mockup{
    margin-top: 2rem;
    position: relative;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 500px) {
      display: none;
    }
    .mockup-url{
      position: absolute;
      top: 10%;
      left: 2%;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 20px;

      /* Preto-dark */
      color: #3C3C3C;
      
    }
    .mockup-name{
      position: absolute;
      top: 62%;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 24px;
      text-align: center;
      left: 0;
      right: 0;
      /* Preto-dark */
      color: #3C3C3C;
    }
  }
  
}
.site{
  
  .navbar{
    background-color: $body-background-color
  }
  &.loyalty{
    .hero-full{
      position: relative;
      background-color: $body-background-color;
      // margin-top: 42px;
      .hero-body {
        padding-top: 0;
        .hero-gradient{
          background: rgb(244,246,251, 0.5);
        }
        .subtitle{
          ul{
            text-align: center;
            list-style-position: inside;
            li{
              list-style-type: disc;
            }
          }
        }
      }
      .hero-avatars{
        // z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../images/site/hero_complete.png');
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-size: contain;
        background-position: center top;
        @media(max-width: $desktop) {
          opacity: 0.2;
        }
        @media(min-width: $fullhd) {
          background-size: cover;
          background-position: center top;
        }
      }
      
    }
    .section-mockup-image{
      padding-bottom: 0;
      margin-bottom: -5rem;
    }
  }
  &.index_2 {
    .hero-full{
      position: relative;
      // margin-top: 42px;
      .hero-body {
        padding-top: 0;
        .hero-gradient{
          background: rgb(244,246,251, 0.5);
        }
        .subtitle{
          ul{
            text-align: center;
            list-style-position: inside;
            li{
              list-style-type: disc;
            }
          }
        }
      }
      .hero-avatars{
        // z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../images/site/hero_complete.png');
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-size: contain;
        background-position: center top;
        @media(max-width: $desktop) {
          opacity: 0.2;
        }
        @media(min-width: $fullhd) {
          background-size: cover;
          background-position: center top;
        }
      }
      
    }
    .section {
      @media(max-width: $tablet) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .navbar-menu {
      .button {
        &.is-text{
          font-weight: normal;
          text-decoration: none;
        }
      }
    }
    .button {
      font-weight: bold;
      
    }
    .title, .subtitle {
      color: $black;
    }
    img.float-coin{
      // z-index: -1;
      @media(max-width: $desktop) {
        display: none;
      }
    }
    
    .section-community{
      position: relative;
      .float-coin{
        position: absolute;
        top: 20px;
        left: 20px;
        width: 15%;
        max-width: 65px;
      }
    }
    .section-missions{
      position: relative;
      .float-coin{
        position: absolute;
        top: 40px;
        right: 40px;
        width: 15%;
        max-width: 65px;
      }
    }
    .section-plans{
      position: relative;
      .float-coin{
        position: absolute;
        &.blur{
          top: 50px;
          left: 50px;
          width: 15%;
          max-width: 65px;
        }
        &.static{
          bottom: -15px;
          right: -15px;
          width: 15%;
          max-width: 75px;
        }
      }
    }
    .section-rewards{
      position: relative;
      .float-coin{
        position: absolute;
        bottom: 40px;
        right: 40px;
        width: 15%;
        max-width: 65px;
      }
    }
    .section-live{
      position: relative;
      .float-coin{
        position: absolute;
        top: 50px;
        left: 50px;
        width: 15%;
        max-width: 75px;
      }
    }
    .section-nfts{
      position: relative;
      img{
        max-width: 300px;
        margin: auto
      }
      .float-coin{
        position: absolute;
        top: 50px;
        left: 0;
        width: 15%;
        max-width: 65px;
      }
    }
    .section-streamer{
      position: relative;
      background: linear-gradient(180deg, #FFEC38 59.18%, #FFBD21 142.82%);
      .float-coin{
        position: absolute;
        
        &.blur{
          bottom: 50px;
          left: 50px;
          width: 15%;
          max-width: 75px;
        }
        &.static{
          top: 50px;
          right: -15px;
          width: 15%;
          max-width: 75px;
        }

      }
    }
    .section-start-rewards{
      position: relative;
      .float-coin{
        position: absolute;
        bottom: 50px;
        left: 50px;
        width: 15%;
        max-width: 75px;
      }
    }
    .section-mockup-image{
      padding-bottom: 0;
      margin-bottom: -5rem;
    }
    .section-mockup-text{
      // padding-top: 0;
      background: linear-gradient(180deg, #FFEC38 50%, #FFBD21 159%);
      margin-top: 0;
      // margin-top: -126px;
      @media(min-width: $desktop) {
        margin-top: -115px;
      }
      @media(max-width: $desktop) {
        padding-top: 4rem;
      }
    }
    .section-dashboard{
      background: linear-gradient(180deg, #FFEC38 50%, #FFBD21 159%);
    }
    .section-testimonials{
      background: linear-gradient(180deg, #FFEC38 50%, #FFBD21 215.87%);
    }
    .section-pricing {
      .card {
        width: 100%;
        small{
          font-size: 50%;
        }
      }
      ul { 
        margin-left: 0;
        li {
          list-style: none;
        }
      }
      .card-footer{
        border: none;
        
        height: 150px;
        .card-footer-item {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
    .testimonial-carousel{
      .carousel-cell {
        width: 100%; /* full width */
        // height: 160px; /* height of carousel */
        margin-right: 10px;
      }
    }
    .cases-carousel {
      .flickity-viewport {
        transition: height 0.2s;
      }
      .carousel-cell {
        // background-color: #f4f6fb;
        // box-shadow: 0 14px 26px -12px rgb(54 54 54 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(54 54 54 / 20%);
        @media(max-width: $tablet) {
          width: 100%;
        }
        width: 50%;
        opacity: 0.2;
        transition: opacity 0.4s;
        // height: 200px;
        @media(min-width: $tablet) {
          margin-right: 50px;
        }
        margin-right: 10px;
        // background: #8C8;
        // border-radius: 5px;
        // counter-increment: carousel-cell;
        &.is-selected {
          // width: 66%;
          opacity: 1;
          cursor: pointer;
        }
      }
      /* cell number */
      .carousel-cell:before {
        display: block;
        text-align: center;
        // content: counter(carousel-cell);
        // line-height: 200px;
        // font-size: 80px;
        // color: white;
      }
      .flickity-prev-next-button {
        &.previous{
          @media(min-width: $tablet) {
            left: 20%;
          }
        }
        &.next{
          @media(min-width: $tablet) {
            right: 20%;
          }
        }
      }
    }
    .personas-carousel {
      @media(max-width: $tablet) {
        margin-top: 20px;
      }
      .flickity-viewport {
        // padding-left: 35px;
        // padding-right: 35px;
      }
      .carousel-cell {
        width: 100%; /* full width */
        // height: 160px; /* height of carousel */
        margin-right: 10px;
        padding-left: 35px;
        padding-right: 35px;
        @media(max-width: $tablet) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .flickity-prev-next-button {
        @media(max-width: $tablet) {
          display: none;
        }
        &.previous{
          @media(min-width: $tablet) {
            left: -20px;
          }
        }
        &.next{
          @media(min-width: $tablet) {
            right: -20px;
          }
        }
      }
      /* no circle */
      .flickity-button {
        background: transparent;
      }
      /* icon color */
      .flickity-button-icon {
        fill: $warning;
      }
      /* hide disabled button */
      .flickity-button:disabled {
        display: none;
      }
      .flickity-page-dots{
        .dot {
          background: $warning;
          @media(min-width: $tablet) {
            display: none;
          }
        }
      }
    }
    .section-partners{
      .partners-block{
        max-width: 500px;
        img {
          max-width: 120px;
        }
      }
      
    }
  }
  &.index{
    
    .blurred-section {
      position: relative;
      // background: radial-gradient(ellipse at top, #e66465, transparent), radial-gradient(ellipse at bottom, #4d9f0c, transparent);
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      background-position: top;
      background-image: url('../../images/mockup/mockup_home_3.png');
      height: 600px;
      .container{
        z-index: 1;
      }
    }
    .blurred-section:after {
        // content: '';
        // width: 100%;
        // height: 100%;
        // background: inherit; 
        // position: absolute;
        // left: 0;
        // right: 0;
        // top: 0;  
        // bottom: 0;
        // box-shadow: inset 0 0 0 200px rgba(255,255,255,0.05);
        // filter: blur(10px);
        // z-index: 0;
    }
    .mockup-screen{
      // border: 1px solid $dark;
      box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    }
    .coin-name-typewriter{
      border-bottom: 1px solid $grey-light;
    }
    .coin-name-title{
      // color: $orange !important;
    }
    .coin-name-container{
      color: $orange !important;
    }
    .input-coin-name{
      color: $orange !important;
      border:none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      caret-color: transparent;
      background-color: transparent;
      margin: 0;
      padding: 0;
      &:active, &:focus{
        border: none;
        outline: none;
        caret-color: $orange;
      }
    }
    body{
      background-color: #ffffff;
      background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23f1f3f5' fill-opacity='0.48' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
    .arrow-icon{
      width: 100%;
      max-width: 75px;
    }
    .left-arrow{
      // position: absolute;
      // left: 0;
      // top: 0;
      transform: rotate(-30deg);
    }
    .right-arrow{
      // position: absolute;
      // right: 0;
      // top: 0;
      transform: rotate(30deg);
    }
    .arrow-hand{
      font-family: 'Covered By Your Grace', cursive;
      // font-family: 'Gochi Hand', cursive;
      font-size: 3rem;
      
    }
    .arrow-hand-left{
      transform: rotate(45deg);
      position: absolute;
      left: -30px;
      bottom: -60px;
    }
    .arrow-hand-right{
      transform: rotate(-45deg);
      position: absolute;
      right: -5px;
      bottom: -60px;
    }
    .header-image{
      padding-bottom: 300px;
    }
    .pricing-features-list{
      li{
        list-style: none;
      }
    }
    .section-body{
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .faq-container {
      .box {
        @media(min-width: $desktop) {
          padding: 2.5rem;
        }
      }
    }

    .tabs-left {

      ul {
        display: block;

        li + li {
          margin-left: 0;
          margin-top: 1rem;
        }

        li{
          a {
            border-bottom-left-radius: 290486px;
            border-top-left-radius: 290486px;
            padding-left: 1.25em;
            border-bottom-right-radius: 290486px;
            border-top-right-radius: 290486px;
            padding-right: 1.25em;    
            background-color: $primary;
            color: $white;        
          }
          
          &.is-active a {
            background-color: $primary;
            color: $white;
          }
          &:not(.is-active) a{
            background: white;
            color: $primary;
          }
        }
      }
    }
  }
  &.signup{
    .confetti-container{
      position: absolute;
    }
    input.verification-code{
      letter-spacing: 1rem;
      font-size: 1rem;
      text-align: center;
    }
    input.lead_user_community_url{
      padding-left: 130px;
    }
    .lead_user_community_url_domain{
      position: absolute;
      left: 5px;
      top: 12px;
      z-index: 2;
      font-size: 12px;
      color: $grey-light;
    }
    input.community_user_input_community_url{
      padding-left: 130px;
    }
    .community_user_input_community_url_domain{
      position: absolute;
      left: 5px;
      top: 12px;
      z-index: 2;
      font-size: 12px;
      color: $grey-light;
    }
    .lead_user_community_coin_acronym{
      text-transform: uppercase;
    }
    .onboard-ilustration{
      max-width: 50%;
    }
  }
}
.onboard-split{
  
  .bg-split-color{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 50%;
    height: 100%;
    background-color: $black;
    z-index: 1;
  }
  .bg-split-content {
    position: relative;
    z-index: 2;
    text-align: center;
    .bg-split-color-content {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 50%;
      height: 100%;
      background-color: $yellow;
      z-index: 1;

      border-radius: 6px 0px 0px 6px;
      box-shadow: 0 0.5em 1em -0.125em rgba(32, 32, 32, 0.1), 0 0px 0 1px rgba(32, 32, 32, 0.02);
      padding: 1.25rem;
    }
    .column{
      z-index: 2;
    }
    .box {
      position: relative;
    }
    form{
      padding: 25px;
      @media(max-width: $tablet) {
        padding: 0;
      }
    }
    .hero-body{
      padding: 1rem;
    }
  }
}
/* Background data (Original source: https://subtlepatterns.com/grid-me/) */
// $bg-url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC";
// $bg-width: 50px;
// $bg-height: 50px;

// /* Animations */
// @-webkit-keyframes bg-scrolling-reverse {
//   100% { background-position: $bg-width $bg-height; }
// }
// @-moz-keyframes    bg-scrolling-reverse {
//   100% { background-position: $bg-width $bg-height; }
// }
// @-o-keyframes      bg-scrolling-reverse {
//   100% { background-position: $bg-width $bg-height; }
// }
// @keyframes         bg-scrolling-reverse {
//   100% { background-position: $bg-width $bg-height; }
// }

// @-webkit-keyframes bg-scrolling {
//   0% { background-position: $bg-width $bg-height; }
// }
// @-moz-keyframes    bg-scrolling {
//   0% { background-position: $bg-width $bg-height; }
// }
// @-o-keyframes      bg-scrolling {
//   0% { background-position: $bg-width $bg-height; }
// }
// @keyframes         bg-scrolling {
//   0% { background-position: $bg-width $bg-height; }
// }

// /* Main styles */
// body {
//   margin-top: 13.5rem;
  
//   color: rgba(153, 153, 153, 0.3);
//   font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
//   text-align: center;
//   /* img size is 50x50 */
//   background: url($bg-url) repeat 0 0;
  
//   -webkit-animation: bg-scrolling-reverse .92s infinite; /* Safari 4+ */
//   -moz-animation:    bg-scrolling-reverse .92s infinite; /* Fx 5+ */
//   -o-animation:      bg-scrolling-reverse .92s infinite; /* Opera 12+ */
//   animation:         bg-scrolling-reverse .92s infinite; /* IE 10+ */
//   -webkit-animation-timing-function: linear;
//   -moz-animation-timing-function:    linear;
//   -o-animation-timing-function:      linear;
//   animation-timing-function:         linear;
  
// }
