.notification-alert-container {
  max-width: 90%;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 100;
  width: 400px;
  @media (max-width: $tablet) {
    right: 5%;
    bottom: 5%;
  }
  .notification-alert-component {
    .close-button{
      border-color: $grey-light;
      background-color: $grey-light;
      color: $white;
      margin: auto;
    }
  }
}
