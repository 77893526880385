.analytics-dashboard {
  .dashboard-tile {
      @extend .s-card;

      font-family: $font;

      .tile-head {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h3 {
              font-family: $font-alt;
              color: $dark-text;
              font-weight: 600;
          }
      }

      .tile-body {
          font-size: 2rem;
          padding: 4px 0 8px 0;

          span {
              color: $dark-text;
              font-weight: 600;
          }
      }

      .tile-foot {
          span {
              &:first-child {
                  font-weight: 500;

                  svg {
                      height: 16px;
                      width: 16px;
                      margin-right: 6px;
                      stroke-width: 3px;
                  }
              }

              &:nth-child(2) {
                  color: $light-text;
                  font-size: .9rem;
              }
          }
      }
  }

  .dashboard-card {
      @extend .s-card;

      font-family: $font;
      height: 100%;

      .card-head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          h3 {
              font-family: $font-alt;
              font-size: 1rem;
              font-weight: 600;
              color: $dark-text;
          }
      }

      .revenue-stats {
          display: flex;

          .revenue-stat {
              margin-right: 30px;
              font-family: $font;

              span {
                  display: block;

                  &:first-child {
                      color: $light-text;
                      font-size: .9rem;
                  }

                  &:nth-child(2) {
                      color: $dark-text;
                      font-size: 1.2rem;
                      font-weight: 600;
                  }

                  &.current {
                      color: $accent;
                  }
              }
          }
      }

      .radial-wrap {
          display: flex;
          flex-direction: column;
          height: calc(100% - 44px);

          .radial-stats {
              margin-top: auto;
              display: flex;
              padding-top: 20px;
              border-top: 1px solid darken($fade-grey, 3%);

              .radial-stat {
                  width: 50%;
                  text-align: center;

                  &:first-child {
                      border-right: 1px solid darken($fade-grey, 3%);
                  }

                  span {
                      display: block;

                      &:first-child {
                          color: $light-text;
                          font-size: .9rem;
                      }

                      &:nth-child(2) {
                          color: $dark-text;
                          font-size: 1.3rem;
                          font-weight: 600;
                      }
                  }
              }
          }
      }

      .progress-block {
          display: flex;
          flex-direction: column;
          height: calc(100% - 44px);
          font-family: $font;

          .value {
              font-size: 1.4rem;
              font-weight: 600;

              span {
                  color: $dark-text;
              }
          }

          .progress {
              margin-bottom: 8px;
          }

          .progress-foot {
              span {
                  &:first-child {
                      font-weight: 500;

                      svg {
                          height: 16px;
                          width: 16px;
                          margin-right: 6px;
                          stroke-width: 3px;
                      }
                  }

                  &:nth-child(2) {
                      color: $light-text;
                      font-size: .9rem;
                  }
              }
          }

          .circle-chart-wrapper {
              margin-top: auto;
          }
      }
  }
}