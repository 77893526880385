// @import "custom-variables";

.iziToast{
  &.iziToast-theme-dark{
    .iziToast-message, .iziToast-title, .iziToast-icon{
      color: $black !important;
    }
  }
  // Theme Dark
  
  &.iziToast-theme-dark{
    background: #efebff;
    border-color: #3800f0;
    &.iziToast-icon{
      color: #3800f0;
    }
  }
  // Other theme colors
  &.iziToast-color-red{
    background: #fdecec;
    border-color: #bc1010;
    &.iziToast-icon{
      color: #bc1010;
    }
  }
  &.iziToast-color-orange{
    background: #fffdeb;
    border-color: #FFBD21;
    &.iziToast-icon{
      color: #FFBD21;
    }
  }
  &.iziToast-color-yellow{
    background: #fffdeb;
    border-color: #948600;
    &.iziToast-icon{
      color: #948600;
    }
  }
  &.iziToast-color-blue{
    background: #eef6fc;  
    border-color: #1d72aa;  
    &.iziToast-icon{
      color: #1d72aa;
    }
  }
  &.iziToast-color-green{
    background: #f0f9f2;
    border-color: #296b35;
    &.iziToast-icon{
      color: #296b35;
    }
    
  }
}