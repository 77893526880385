/*! _forms-slider.scss | Huro | Css ninja 2020-2021 */

/*
    1. NoUI Slider
    2. NoUI Slider Dark mode
*/

/* ==========================================================================
1. NoUI Slider
========================================================================== */

.noUi-horizontal, .noUi-vertical {
    border: none;
    box-shadow: none;

    &.is-info {
        .noUi-connect {
            background: $info;
        }

        .noUi-tooltip {
            background: $info;
            color: $smoke-white;

            &:after {
                background: $info;
            }
        }
    }

    &.is-success {
        .noUi-connect {
            background: $success;
        }

        .noUi-tooltip {
            background: $success;
            color: $smoke-white;

            &:after {
                background: $success;
            }
        }
    }

    &.is-warning {
        .noUi-connect {
            background: $warning;
        }

        .noUi-tooltip {
            background: $warning;
            color: $smoke-white;

            &:after {
                background: $warning;
            }
        }
    }

    &.is-danger {
        .noUi-connect {
            background: $danger;
        }

        .noUi-tooltip {
            background: $danger;
            color: $smoke-white;

            &:after {
                background: $danger;
            }
        }
    }

    .noUi-connects {
        background: darken($widget-grey, 2%);
        border: none !important;
    }

    .noUi-connect {
        background-color: $primary;
    }

    .noUi-handle {
        outline: none !important;
        box-shadow: $light-box-shadow;
    }
}

.noUi-horizontal {
    &.is-small {
        height: 10px;

        &.is-rounded {
            .noUi-handle {
                border-radius: 50%;
            }
        }

        .noUi-handle {
            width: 22px;
            height: 22px;
            top: -6px;

            &:before {
                height: 10px;
                top: 5px;
                left: 8px;
            }

            &:after {
                height: 10px;
                top: 5px;
                left: 11px;
            }
        }
    }

    &.is-tiny {
        height: 6px;

        &.is-rounded {
            .noUi-handle {
                border-radius: 50%;
            }
        }

        .noUi-handle {
            width: 18px;
            height: 18px;
            top: -6px;

            &:before {
                height: 10px;
                top: 3px;
                left: 6px;
            }

            &:after {
                height: 10px;
                top: 3px;
                left: 9px;
            }
        }
    }

    .noUi-handle {
        width: 28px;
        height: 28px;

        &:before {
            left: 11px;
        }

        &:after {
            left: 16px;
        }
    }

    .noUi-tooltip {
        background: $primary;
        color: $smoke-white;

        &:after {
            background: $primary;
        }
    }
}

.noUi-vertical {
    height: 180px;

    &.is-small {
        width: 10px;

        &.is-rounded {
            .noUi-handle {
                border-radius: 50%;
            }
        }

        .noUi-handle {
            width: 22px;
            height: 22px;
            top: -6px;

            &:before {
                width: 10px !important;
                top: 7px !important;
                left: 6px !important;
            }

            &:after {
                width: 10px !important;
                top: 11px !important;
                left: 6px !important;
            }
        }
    }

    &.is-tiny {
        width: 6px;

        &.is-rounded {
            .noUi-handle {
                border-radius: 50%;
            }
        }

        .noUi-handle {
            width: 18px;
            height: 18px;
            top: -6px;

            &:before {
                width: 8px !important;
                top: 6px !important;
                left: 4px !important;
            }

            &:after {
                width: 8px !important;
                top: 9px !important;
                left: 4px !important;
            }
        }
    }

    .noUi-handle {
        width: 28px;
        height: 28px;

        &:before {
            left: 7px;
            top: 10px;
        }

        &:after {
            left: 7px;
            top: 15px;
        }
    }
}

.noUi-horizontal, .noUi-vertical {
    &.has-fixed-tooltip {
        .noUi-tooltip {
            opacity: 1 !important;
            pointer-events: all !important;
            transform: translate(-50%, 0) scale(1) !important;
        }
    }

    .noUi-tooltip {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-family: $font;
        font-size: .9rem;
        opacity: 0;
        pointer-events: none;
        transform: translate(-50%, 10%) scale(0.8);
        border-radius: 50%;
        background: $primary;
        position: absolute;
        left: 50%;
        top: -70px;
        transition: all .3s;
        padding: 0;

        &:after {
            content: '';
            position: absolute;
            top: -1px;
            left: -1px;
            width: 50px;
            height: 50px;
            border-radius: 50% 50% 50% 0;
            transform: rotate(-45deg);
            background: $primary;
            z-index: -1;
        }
    }

    .noUi-active .noUi-tooltip {
        opacity: 1;
        pointer-events: all;
        transform: translate(-50%, 0) scale(1);
    }
}


/* ==========================================================================
2. NoUI Slider Dark mode
========================================================================== */

body {
    &.is-dark {
        .noUi-horizontal, .noUi-vertical {

            &.is-info {
                .noUi-connect {
                    background: $info;
                }
        
                .noUi-tooltip {
                    background: $info;
        
                    &:after {
                        background: $info;
                    }
                }
            }
        
            &.is-success {
                .noUi-connect {
                    background: $success;
                }
        
                .noUi-tooltip {
                    background: $success;
        
                    &:after {
                        background: $success;
                    }
                }
            }
        
            &.is-warning {
                .noUi-connect {
                    background: $warning;
                }
        
                .noUi-tooltip {
                    background: $warning;
        
                    &:after {
                        background: $warning;
                    }
                }
            }
        
            &.is-danger {
                .noUi-connect {
                    background: $danger;
                }
        
                .noUi-tooltip {
                    background: $danger;
        
                    &:after {
                        background: $danger;
                    }
                }
            }

            .noUi-connects {
                background: lighten($dark-sidebar, 2%);
            }
        
            .noUi-connect {
                background-color: $accent;
            }

            .noUi-handle {
                background: $dark-sidebar;
                border-color: lighten($dark-sidebar, 12%);

                &:before, &:after {
                    background: lighten($dark-sidebar, 20%);
                }
            }

            .noUi-tooltip {
                background: $accent;

                &:after {
                    background: $accent;
                }
            }
        }
    }
}