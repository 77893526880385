/*! _responsive.scss | Huro | Css ninja 2020-2021 */

/* ==========================================================================
Responsive styles
========================================================================== */

/*
    1. Tablet Landscape
    2. Tablet portrait
    3. Mobile Only
    4. Custom
    5. Responsive Helpers
*/

/* ==========================================================================
1. Tablet Landscape
========================================================================== */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    //Loaders
    .pageloader, .infraloader {
        width: calc(100% - 60px) !important;
        left: 60px !important;
    }

    //Navbars
    .navbar-brand {
        align-items: center;
    }

    //Sidebar
    .main-sidebar {
        width: 60px !important;

        &.has-labels {
            .sidebar-inner {
                .icon-menu, .bottom-menu {
                    li {
                        a {
                            &::after {
                                left: -30px;
                            }
                        }
                    }
                }
            }
        }

        &.is-float {
            width: 56px !important;
            margin-left: 4px;
            
            &:not(.is-bordered) {
                .sidebar-brand {
                    width: 56px !important;
                }
        
                .sidebar-inner {
                    .icon-menu, .bottom-menu {
                        li {
                            width: 56px !important;
                        }
                    }
                }
            }

            &.is-bordered {
                width: 60px !important;

                .sidebar-brand {
                    width: 60px !important;
                }
        
                .sidebar-inner {
                    .icon-menu, .bottom-menu {
                        li {
                            width: 60px !important;
                        }
                    }
                }
            }

            .sidebar-inner {
                .bottom-menu {
                    padding-bottom: 0 !important;
                    bottom: -3px !important;
                }
            }
        }

        .sidebar-brand {
            width: 60px !important;
        }

        .sidebar-inner {
            li {
                width: 60px !important;
            }

            .profile-dropdown {
                .dropdown-trigger img {
                    height: 42px !important;
                    width: 42px !important;
                }
            }
        }
    }

    .sidebar-panel {
        box-shadow: $light-box-shadow;

        .panel-close {
            display: block !important;
        }
    }

    .sidebar-panel, .sidebar-search {
        left: 60px;
    }

    .mobile-main-sidebar, .mobile-subsidebar {
        display: none !important;
    }

    //Layout
    .view-wrapper {
        width: calc(100% - 60px) !important;
        margin-left: 60px !important;

        &.is-pushed-messages {
            margin-left: 140px !important;
            width: calc(100% - 140px) !important;
        }

        &.is-webapp {
            .is-stuck {
                margin-left: 0 !important;
                width: 100% !important;
            }
        }
    }

    .page-title {
        &.is-project-details {
            .huro-hamburger {
                margin-left: -4px !important;
            }
        }

        &.is-pulled-lg {
            .huro-hamburger {
                margin-left: 0 !important;
            }
        }
    }

    .page-content {
        padding: 0 30px !important;
    }

    .is-stuck {
        margin-left: 60px !important;
        width: calc(100% - 60px) !important;
    }
}

/* ==========================================================================
2. Tablet portrait
========================================================================== */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    //Loaders
    .infraloader, .pageloader {
        width: 100% !important;
        left: 0 !important;
    }

    //Navbars
    .webapp-navbar, .circular-menu, .webapp-navbar-clean {
        display: none;
    }

    body:not(.mobile-focus) {
        .webapp-navbar, .circular-menu, .webapp-navbar-clean{
            display: block;
        }
    }

    .mobile-navbar {
        display: flex;
    }

    .navbar-brand {
        align-items: center;
        justify-content: space-between;
        height: 60px;

        .brand-start {
            width: 90px;

            .navbar-burger {
                height: 60px;
                width: 60px;
            }
        }

        .is-brand {
            padding: 0 !important;
        }

        .brand-end {
            display: flex;
            align-items: center;
            width: 90px;
            margin-right: 20px;
        }

        .is-notification {
            &.is-active, &:hover {
                .navbar-link {
                    background: transparent !important;
                }

                .navbar-dropdown {
                    display: block !important;
                }
            }

            .navbar-dropdown {
                position: absolute;
                display: none;
                position: absolute;
                top: 100%;
                z-index: 3;
                background: $white;
                border-radius: 6px;
                border: 1px solid $fade-grey;
                box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
            }
        }
    }

    //Toolbar
    .toolbar {
        .toolbar-notifications {
            display: none;
        }
    }

    .huro-hamburger:not(.is-visible) {
        display: none !important;
    }

    //Sidebars
    .main-sidebar, .sidebar {
        display: none;
    }

    .sidebar-panel {
        display: none !important;
        left: 0 !important;
        z-index: 100 !important;
        box-shadow: $light-box-shadow;

        .panel-close {
            display: flex !important;
        }
    }

    //Layout
    .view-wrapper {
        width: 100% !important;
        margin-left: 0 !important;
        margin-top: 60px !important;
        padding-left: 40px;
        padding-right: 40px;

        &.is-webapp {
            padding-top: 70px !important;
            margin-top: 0 !important;
        }

        &.is-explore {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .page-title {
        &.is-webapp {
            display: flex !important;
            margin-top: 0;
        }

        &.is-project-details {
            .huro-hamburger {
                margin-left: 7px !important;
            }
        }
    }

    .page-content {
        padding: 0 10px;

        &.is-explore {
            padding: 0 !important;
        }
    }

    .is-stuck {
        top: 60px !important;
        margin-left: 0 !important;
        width: 100% !important;
        padding-right: 20px !important;
    }
}

/* ==========================================================================
3. Mobile Only
========================================================================== */

@media (max-width: 767px) {
    //Loaders
    .infraloader, .pageloader {
        width: 100% !important;
        left: 0 !important;
    }

    //Navbars
    .mobile-navbar {
        display: flex;
    }

    .webapp-navbar, .circular-menu, .webapp-navbar-clean {
        display: block;
    }

    body:not(.mobile-focus) {
        .webapp-navbar, .circular-menu, .webapp-navbar-clean{
            display: block;
        }
    }

    .navbar-brand {
        align-items: center;
        justify-content: space-between;
        height: 60px;

        .brand-start {
            width: 90px;

            .navbar-burger {
                height: 60px;
                width: 60px;
            }
        }

        .is-brand {
            padding: 0 !important;
        }

        .brand-end {
            display: flex;
            align-items: center;
            width: 90px;
            // margin-right: 20px;
        }

        .is-notification {
            &.is-active, &:hover {
                .navbar-link {
                    background: transparent !important;
                }

                .navbar-dropdown {
                    display: block !important;
                }
            }

            .navbar-dropdown {
                position: absolute;
                display: none;
                position: absolute;
                top: 100%;
                z-index: 3;
                background: $white;
                border-radius: 6px;
                border: 1px solid $fade-grey;
                box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
            }
        }
    }

    //Toolbar
    .toolbar {
        .toolbar-notifications {
            display: none;
        }
    }

    .huro-hamburger:not(.is-visible) {
        display: none !important;
    }

    //Sidebar
    .sidebar-panel {
        display: none !important;
        left: 0 !important;
        z-index: 100 !important;
        border-right: 1px solid $fade-grey !important;
        box-shadow: $light-box-shadow;

        .subpanel-header {
            height: 60px !important;
            background: #fafafa;

            svg {
                stroke: $muted-grey;
            }
        }
    }

    .main-sidebar, .sidebar {
        display: none;
    }

    //Layout
    .view-wrapper {
        width: 100% !important;
        margin-left: 0 !important;
        margin-top: 60px !important;

        &.is-webapp {
            padding-top: 0 !important;
        }
    }

    .page-title {
        margin-top: 20px;

        &.is-webapp {
            display: flex !important;
            margin-top: 0;
        }

        &.is-project-details {
            .huro-hamburger {
                margin-left: -4px !important;
            }
        }

        .title-wrap {
            margin-left: 0;
        }
    }

    .page-content {
        padding: 0 10px 0 10px !important;

        &.projects-content {
            padding: 0 16px;
        }
    }

    .is-stuck {
        top: 59px !important;
        margin-left: 0 !important;
        width: 100% !important;
        padding-right: 20px !important;
    }
}

/* ==========================================================================
4. Custom
========================================================================== */

@media only screen and (min-device-width: 1025px) and (orientation: landscape) {
    .panel-close {
        display: none !important;
    }

    .mobile-main-sidebar, .mobile-subsidebar {
        display: none !important;
    }
}

/* ==========================================================================
5. Responsive Helpers
========================================================================== */

@media only screen and (max-width: 767px) {
    .h-hidden-mobile {
        display: none !important;
    }

    .h-only-mobile {
        display: block !important;
    }

    .h-only-mobile-flex {
        display: flex !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .h-hidden-tablet-p {
        display: none !important;
    }

    .is-flex-tablet-p {
        display: flex !important;
    }

    .h-only-tablet-p {
        display: block !important;
    }

    .h-only-tablet-p-flex {
        display: flex !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .h-hidden-tablet-l {
        display: none !important;
    }

    .is-flex-tablet-l {
        display: flex !important;
    }

    .h-only-tablet-l {
        display: block !important;
    }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
    .h-only-mobile {
        display: block !important;
    }

    .h-only-mobile-flex {
        display: none !important;
    }

    .h-only-tablet-p {
        display: none !important;
    }

    .h-only-tablet-p-flex {
        display: none !important;
    }
}

@media only screen and (min-width: 1025px) {
    .h-hidden-desktop {
        display: none !important;
    }
}
