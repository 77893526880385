.component-home-ranking{
  .leaderboard-podium {
    .leaderboard-first {
      figure {
        filter: drop-shadow(0px 0px 15px rgba(255, 189, 33, 0.95));
      }
    }
  }
  .ranking-list{
    display: none;
  }
}