/*! _utilities.scss | Huro | Css ninja 2020-2021 */

/*
    1. Titles
    2. Divider
    3. Helpers
    4. Hamburger
    5. Tooltips
    6. Alertify js
    7. Alertify js Dark mode
    8. Divider alt
    9. Text tips
    10. Text tips Dark mode
    11. Hopscotch
    12. Demo toggler
    13. Background images
    14. Dark mode images
    15. Demo spacer
    16. Simplebar
    17. Back to top button
*/

/* ==========================================================================
1. Titles
========================================================================== */

.title, .subtitle {
    &.light-mb {
        margin-bottom: 10px;
    }

    &.no-mb {
        margin-bottom: 0;
    }

    &.has-margin-top {
        margin-top: 20px;
    }

    &.has-margin-top-lg {
        margin-top: 50px;
    }

    &.is-purple {
        color: $primary;
    }

    &.is-light {
        color: $white;
    }
}

var {
    font-style: inherit;
}

.has-text-centered {
    text-align: center;
}

/* ==========================================================================
2. Divider
========================================================================== */

.divider-container {
    max-width: 640px;
    margin: 0 auto;

    .divider {
        text-align: center;
        margin: 1.5em auto 1em auto;
        width: 100%;
        position: relative;

        span {
            display: inline-block;
            margin-top: 1px;
            font-size: 1rem;
            text-transform: none;
            color: $accent-grey;

            &:before, &:after {
                width: 36%;
                display: block;
                position: absolute;
                left: 0;
                top: 0.9em;
                height: 1px;
                content: " ";
                border-top: 1px solid $accent-grey;
            }

            &:after {
                right: 0;
                left: auto;
            }
        }
    }
}

@media (max-width: 768px) {
    .divider span:before,
    .divider span:after {
        width: 22% !important;
    }
}

/* ==========================================================================
3. Helpers
========================================================================== */

.is-flex-wrapped {
    flex-wrap: wrap !important;
}

.is-hidden {
    display: none !important;
}

.is-vhidden {
    visibility: hidden !important;
}

.no-click {
    pointer-events: none !important;
}

.is-disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default !important;
}

.ml-auto {
    margin-left: auto !important;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-mb {
    margin-bottom: 0 !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.is-green {
    color: $success;
}

.is-purple {
    color: $primary;
}

.has-slimscroll {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        background: rgba(0, 0, 0, 0.2) !important;
    }
}

.has-slimscroll-sm {
    &::-webkit-scrollbar {
        width: 3px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        background: rgba(0, 0, 0, 0.2) !important;
    }
}

.dark-text {
    color: $dark-text;
}

.light-text {
    color: $light-text;
}

.success-text {
    color: $success;
}

.info-text {
    color: $info;
}

.warning-text {
    color: $warning;
}

.danger-text {
    color: $danger;
}

.inverted-text {
    color: $smoke-white;
}

/* ==========================================================================
4. Hamburger
========================================================================== */

.menu-toggle {
    font-size: 20px;
    color: #666;
    text-align: center;
    background: transparent;
    display: block;
    width: 26px;
    height: 26px;
    cursor: pointer;
    padding: 0;
    transition: opacity 0.4s;
    opacity: 1;
    position: relative;

    &.has-chevron {
        .icon-box-toggle {
            &.active {
                .icon-line-top {
                    width: 13px !important;
                    top: 17px !important;
                }

                .icon-line-bottom {
                    width: 13px !important;
                    top: 9px !important;
                }
            }
        }
    }

    .icon-box-toggle {
        height: 100%;
        width: 100%;
        background: transparent;
        position: relative;
        display: block;
        width: 30px;
        height: 30px;

        &.active > span.rotate {
            -webkit-transform: rotate(90deg);
            -moz-transform: translate(0px, 0px) rotate(90deg);
            -ms-transform: translate(0px, 0px) rotate(90deg);
            -o-transform: translate(0px, 0px) rotate(90deg);
            transform: translate(0px, 0px) rotate(90deg);
        }

        &.active > span > i.icon-line-center {
            visibility: hidden;
            width: 1px;
            height: 3px;
            left: 70%;
        }

        &.active > span > i.icon-line-bottom {
            margin: -2px 0 0 -10px;
            left: 50%;
            top: 12px;
            -webkit-transform: rotate(135deg);
            -moz-transform: translate(0px, 0px) rotate(135deg);
            -ms-transform: translate(0px, 0px) rotate(135deg);
            -o-transform: translate(0px, 0px) rotate(135deg);
            transform: translate(0px, 0px) rotate(135deg);
        }

        &.active > span > i.icon-line-top {
            margin: -2px 0 0 -10px;
            left: 50%;
            top: 12px;
            -webkit-transform: rotate(45deg);
            -moz-transform: translate(0px, 0px) rotate(45deg);
            -ms-transform: translate(0px, 0px) rotate(45deg);
            -o-transform: translate(0px, 0px) rotate(45deg);
            transform: translate(0px, 0px) rotate(45deg);
        }
    }

    .icon-line-center {
        position: absolute;
        width: 12px;
        height: 2px;
        background: $primary;
        margin: -1px 0 0 -10px;
        left: 50%;
        top: 12px;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .icon-line-top {
        position: absolute;
        width: 20px;
        height: 2px;
        background: $primary;
        margin: -3px 0 0 -10px;
        left: 50%;
        top: 6px;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .icon-line-bottom {
        position: absolute;
        width: 20px;
        height: 2px;
        background: $primary;
        margin: 2px 0 0 -10px;
        left: 50%;
        top: 17px;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
}

/* ==========================================================================
5. Tooltips
========================================================================== */



/* ==========================================================================
6. Alertify js
========================================================================== */

.alertify .ajs-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    border-radius: 8px !important;
}

.ajs-dimmer {
    background-color: $dark-sidebar !important;
}

.ajs-header {
    font-weight: 600 !important;
    font-family: $font-alt !important;
    border-radius: 8px 8px 0 0 !important;
}

.ajs-footer {
    border-radius: 0 0 6px 6px !important;
    border: none !important;
    background: transparent !important;
}

.ajs-content {
    padding: 16px 0 !important;
    font-family: $font !important;

    b {
        font-weight: 500 !important;
        color: $primary !important;
    }
}

.ajs-buttons {
    padding: 6px 10px;
}

.ajs-close {
    outline: none !important;
}

.ajs-ok {
    padding: 8px 22px 8px 22px;
    font-weight: 600;
    height: 33px;
    min-width: 82px !important;
    line-height: 0;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: .75rem;
    border-radius: 3px;
    background: $primary !important;
    color: $white !important;
    border: 1px solid $primary;
    border-radius: 6px;
    transition: all .3s;
    cursor: pointer;
    outline: none !important;

    &:hover {
        box-shadow: $primary-box-shadow;
    }
}

.ajs-cancel {
    padding: 8px 22px 8px 22px;
    font-weight: 600;
    height: 33px;
    min-width: 82px !important;
    line-height: 0;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: .75rem;
    border-radius: 3px;
    background: $white !important;
    color: $primary !important;
    border: 1px solid $primary !important;
    border-radius: 6px;
    transition: all .3s;
    cursor: pointer;
    outline: none !important;
}

/* ==========================================================================
7. Alertify js Dark mode
========================================================================== */

body {
    &.is-dark {
        .ajs-dialog {
            background: lighten($dark-sidebar, 6%) !important;
            border-color: lighten($dark-sidebar, 2%) !important;
        }

        .ajs-header {
            background: lighten($dark-sidebar, 6%) !important;
            border-color: lighten($dark-sidebar, 6%) !important;
            color: $accent-grey !important;
        }

        .ajs-content {
            color: $dark-dark-text !important;
            
            span {
                color: $dark-dark-text !important;
    
                b {
                    color: $accent !important;
                }
            }
        }

        .ajs-footer {
            background: lighten($dark-sidebar, 6%) !important;
            border-color: lighten($dark-sidebar, 6%) !important;

            .ajs-ok {
                background: $accent !important;
            }

            .ajs-cancel {
                background: lighten($dark-sidebar, 2%) !important;
                border-color: lighten($dark-sidebar, 2%) !important;
                color: $muted-grey !important;
            }
        }
    }
}

/* ==========================================================================
8. Divider alt
========================================================================== */

.divider {
    display: flex;
    color: $muted-grey;
    outline: none !important;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 500;

    &:before,
    &:after {
        content: "";
        flex: 1;
    }

    &.line {
        align-items: center;
        margin: 1em -1em;

        &:before,
        &:after {
            height: 1px;
            margin: 0 1em;
        }
    }

    &.one-line {
        &:before,
        &:after {
            background: darken($fade-grey, 5%);
        }
    }
}

/* ==========================================================================
9. Text tips
========================================================================== */

.has-text-tip, .has-small-text-tip {
    position: relative;
    cursor: pointer;
}

.text-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 999;

    .tooltip-content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px 16px;
        background: $white;
        color: $dark-text;
        border: 1px solid $accent-grey;
        box-shadow: $light-box-shadow;
        border-radius: 6px;
        font-size: .9rem;
    }
}

/* ==========================================================================
10. Text tips Dark mode
========================================================================== */

body {
    &.is-dark {
        .text-tooltip {
            .tooltip-content {
                background: $dark-sidebar;
                border-color: $dark-sidebar;
                color: $accent-grey;
            }
        }
    }
}

/* ==========================================================================
11. Hopscotch
========================================================================== */

.app-wrapper {
    transition: transform .3s;

    &.is-pushed-bottom {
        transform: translateY(50px);
    }
}

.hopscotch-bubble {
    .hopscotch-bubble-container {
        .hopscotch-bubble-number {
            padding: 0;
            background: $primary;
            border-radius: 50%;
            box-shadow: $primary-box-shadow;
            font-size: .8rem;
        }

        .hopscotch-actions {
            .hopscotch-nav-button {
                background: $white;
                text-shadow: none;
                color: $primary;
                border-color: $primary;
                font-weight: 500;
                height: 32px;
                min-width: 80px;
                border-radius: 100px;
                transition: all .3s;

                &:hover {
                    background: $primary;
                    color: $smoke-white;
                }
            }
        }
    }
}

/* ==========================================================================
12. Demo toggler
========================================================================== */

.demo-wrapper {
    position: fixed;
    bottom: -50px;
    right: -50px;
    height: 140px;
    width: 140px;
    background: transparent;
    border-radius: 50%;
    transform: scale(0.4);
    transition: all .3s;

    &:hover {
        background: $white;
        transform: scale(1);

        .form-switch {
            opacity: 1 !important;
            pointer-events: all !important;
        }
    }

    .inner {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .form-switch {
            position: relative;
            top: -10px;
            left: -10px;
            display: block;
            transition: all .3s;
            opacity: 0;
            pointer-events: none;
        }
    }
}

/* ==========================================================================
13. Background images
========================================================================== */

.has-background-image {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    &.is-top {
        background-position: top !important;
    }

    &.is-bottom {
        background-position: bottom !important;
    }
}

/* ==========================================================================
14. Dark mode images
========================================================================== */

body {
    &.is-dark {
        .light-image {
            display: none !important;
        }

        .dark-image {
            display: inline-block !important;
        }

        .light-image-block {
            display: none !important;
        }

        .dark-image-block {
            display: block !important;
        }
    }

    .light-image {
        display: inline-block !important;
    }

    .dark-image {
        display: none !important;
    }

    .light-image-block {
        display: block;
    }

    .dark-image-block {
        display: none;
    }
}

/* ==========================================================================
15. Demo Spacer
========================================================================== */

.demo-spacer {
    width: 100%;
    height: 2px;
    padding: 80px 0;
}

/* ==========================================================================
16. Simplebar
========================================================================== */

.simplebar-scrollbar:before {
    background: rgba(0, 0, 0, 0.2) !important;
}

/* ==========================================================================
17. Back to top button
========================================================================== */

#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transition: 0.35s;
    transform: scale(0.7);
    transition: all 0.5s;

    &.visible {
        opacity: 1;
        visibility: visible;
        transform: scale(1);

        a:hover {
            outline: none;
            opacity: 0.9;
            background: $accent;
            color: $white;
        }
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        text-decoration: none;
        border: 0 none;
        display: block;
        width: 46px;
        height: 46px;
        background-color: $medium-grey;
        opacity: 1;
        transition: all 0.3s;
        border-radius: 50%;
        text-align: center;
        font-size: 20px;
        outline: none;
        color: $dark-text;

        i {
            position: relative;
            top: 6px;
        }
    }
}
